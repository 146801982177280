import { useTranslation } from "_metronic/i18n/language";
import RadarChart from "app/pages/admin/components/RadarChart";
import { FragranceApiService } from "app/services";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import React, { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { calculateTime } from "utils";

interface QuizAnswer {
  otherAnswers: string;
  initDate: string;
  endDateTime: string;
  shots: number;
  question: number;
}

interface ProcessedDataItem {
  value?: number;
  values: number[];
  shots: number;
  initDate: string;
  endDateTime: string;
  questionId: number;
  question: any;
}

interface Props {
  quizAnswers: QuizAnswer[];
  children?: ReactNode;
  questions: any[];
}

const RadarChartComponent: React.FC<Props> = ({
  quizAnswers,
  questions,
  children
}) => {
  const translate = useTranslation();
  const locale = useSelector((state: any) => state.i18n.lang);
  const [fragrances, setFragrances] = useState<
    { fragranceId: number; name: string; fragranceNames: any }[]
  >([]);
  const extractCategories = (answers: QuizAnswer[]): string[] => {
    const categories: Set<string> = new Set();

    answers.forEach(answer => {
      answer.otherAnswers.split(";").forEach(item => {
        const category = item.split(":")[0].trim();
        categories.add(pegarPrimeiraPalavra(category));
      });
    });

    return Array.from(categories);
  };

  const api = new AnanseApiService();

  useEffect(() => {
    getFragrances();
  }, []);

  const getFragrances = async () => {
    try {
      const { total } = await api.makeHttpRequest({ url: "fragrances" });
      const { data } = await api.makeHttpRequest({
        url: `fragrances?PerPage=${total}`
      });
      setFragrances(data);
    } catch (error) {}
  };

  const filterQuestion = (value: any) => {
    const quest: { fragranceId: any } = questions.find((e: any) => {
      return e.questionId === value;
    });
    const fragrance = getFragranceName(Number(quest.fragranceId));
    return { ...quest, fragrance };
  };

  const getFragranceName = (fragranceId: number) => {
    const fragrance = fragrances.find(f => f.fragranceId === fragranceId);
    return fragrance;
  };

  const processData = (answers: QuizAnswer[], categories: string[]) => {
    let prepareChart:{
      data: number[];
      name: string;
  }[] = []
    const result = answers.map((answer, i) => {
      const values: Record<string, number> = {};

      answer.otherAnswers.split(";").forEach(item => {
        const [category, score] = item.split(":").map(str => str.trim());
        const label = pegarPrimeiraPalavra(category);
        if (categories.includes(label)) {
          values[label] = parseInt(score);
        }
      });

      const question = filterQuestion(answer.question);
      const processedItem = {
        questionId: answer.question,
        values: categories.map(category => values[category] || 0),
        shots: answer.shots,
        initDate: answer.initDate,
        endDateTime: answer.endDateTime,
        question
      };
      const chartData = {
        data: categories.map(category => values[category] || 0),
        name: `${i + 1} - ${question.fragrance?.fragranceNames[
          locale.split("-")[0]
        ] || question.fragrance?.name}`
      };
      prepareChart.push(chartData);
      return processedItem;
    });
    return { dataTable: result, chartData: prepareChart};
  };

  function pegarPrimeiraPalavra(frase: string): string {
    const trimmedFrase = frase.trim();

    const primeiroEspaco = trimmedFrase.indexOf(" ");

    if (primeiroEspaco === -1) {
      return trimmedFrase;
    } else {
      return trimmedFrase.substring(0, primeiroEspaco);
    }
  }

  const categories = extractCategories(quizAnswers);
  const chartData = processData(quizAnswers, categories);
  return (
    <div className="print">
      {children}
      <div className="observation">
        <h1>{translate("screenApp.results.detailing")}</h1>
      </div>
      <table className="result">
        <tr className="result">
          <th className="result" />
          <th className="result">{translate("screenApp_fragrance")}</th>
          {categories.map(category => (
            <th key={category} className="result">
              {category}
            </th>
          ))}
          <th className="result">{translate("screenApp_results_shots")}</th>
          <th className="result">{translate("screenApp_results_time")}</th>
        </tr>
        {chartData.dataTable.map((item, index) => (
          <tr key={index} className="result">
            <td className="result">{index + 1}</td>
            <td className="result">
              {item.question.fragrance?.fragranceNames[locale.split("-")[0]] ||
                item.question.fragrance?.name}
            </td>
            {item.values.map((value, i) => (
              <td key={i} className="result">
                {value}
              </td>
            ))}
            <td className="result">{item.shots}</td>
            <td className="result">
              {calculateTime(item.initDate, item.endDateTime)}
            </td>
          </tr>
        ))}
      </table>
      <RadarChart labels={categories} series={chartData.chartData} />
    </div>
  );
};

export default RadarChartComponent;
