import { Box, Card, CardContent, Container } from "@mui/material";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import Logo from "../../../_metronic/_assets/media/logos/login-logo.png";
import Login from "./Login";
import Password from "./Password";
import PassActions from "./Password/enum/Actions";

export default function AuthPage() {
  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Card elevation={5} style={{ width: "100%", maxWidth: 500 }}>
          <CardContent>
            <div className="logo-container" style={{ textAlign: "center" }}>
              <Link to="/" className="kt-login__logo justify-content-center">
                <img
                  alt="Logo"
                  src={Logo}
                  style={{ width: 200, marginBottom: 20 }}
                />
              </Link>
            </div>

            <Switch>
              <Route
                path="/auth/ativar-conta"
                component={() => <Password action={PassActions.CriarConta} />}
              />
              <Route
                path="/auth/recuperar-senha"
                component={() => (
                  <Password action={PassActions.RecuperarSenha} />
                )}
              />
              <Route
                path="/auth/cadastrar-senha"
                component={() => (
                  <Password action={PassActions.CadastrarSenha} />
                )}
              />
              <Route path="/auth/login" component={Login} />
              <Redirect from="/auth" exact={true} to="/auth/login" />
              <Redirect to="/auth/login" />
            </Switch>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
