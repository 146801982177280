import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Card, Col, Form, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import {
  Avatar,
  Box,
  CardMedia,
  Divider,
  Radio,
  Stack,
  Typography
} from "@mui/material";
import { useParams, useHistory } from "react-router-dom";

import { CapsuleI } from "interfaces";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import FormActions from "components/FormActions";
import Loading from "app/pages/home/components/Loading";
import { useTranslation } from "_metronic/i18n/language";

const CapsuleUpdate = () => {
  const params: any = useParams();
  const history = useHistory();
  const api = new AnanseApiService();
  const translate = useTranslation();
  const schema = Yup.object().shape({
    serialNumber: Yup.string()
      .required(translate("screens.login.validations.required"))
      .nullable(),
    dueDate: Yup.date().required(
      translate("screens.login.validations.required")
    )
  });

  const [loading, setLoading] = React.useState(false);
  const [allowSave, setAllowSave] = React.useState(true);
  const [enableButtonSave, setEnableButtonSave] = React.useState(false);
  const [capsuleStatus, setCapsuleStatus] = React.useState<string | null>(null);
  const [valueFragrance, setValueFragrance] = React.useState<any>({});

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      customerCode: "",
      capsuleId: 0,
      deviceId: 0,
      fragranceId: 0,
      dueDate: "",
      updatedAt: "",
      deletedAt: "",
      serialNumber: "",
      remainingShots: 0,
      performedShots: 0,
      macAddress: "",
      campaignName: ""
    }
  });

  React.useEffect(() => {
    loadingData();
  }, []);

  const loadingData = async () => {
    setLoading(true);
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: "settings"
      });
      const result = await getCapsule(params.serialNumber, params?.fragranceId);
      const testCheckCapsuleExists = await checkCapsuleExists(result);
      const campaignResponse = await getCampaignId(
        result.dataResponse.campaignId
      );
      const resultFragrance = campaignResponse.collection.find(
        (item: any) => item.fragranceId === result.fragranceId
      );
      console.log("getCapsule: ", result);

      setValueFragrance(resultFragrance);
      setValue("customerCode", response.customerCode);
      setValue("capsuleId", result.capsuleId);
      setValue("deviceId", result.deviceId);
      setValue("fragranceId", result.fragranceId);
      setValue("dueDate", result.dueDate);
      setValue("serialNumber", result.serialNumber);
      setValue("remainingShots", result.remainingShots);
      if (
        result.dataResponse &&
        result.dataResponse.status !== "Device not found"
      ) {
        setValue("macAddress", result.dataResponse.macAddress);
      }
      if (result.dataResponse && result.dataResponse.campaign) {
        setValue("campaignName", result.dataResponse.campaign.name);
      }
      if (result.deletedAt === null) {
        setCapsuleStatus("ativada");
      } else {
        setCapsuleStatus("desativada");
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getCampaignId = async (campaignId: string) => {
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `campaigns/${campaignId}`
      });
      return response;
    } catch (error) {
      console.error("Erro ao buscar o dispositivo:", error);
    }
  };

  const getCapsulesByDeviceId = async (deviceId: string) => {
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `capsules/device/${deviceId}`
      });
      return response;
    } catch (error) {
      console.error("Erro ao buscar o dispositivo:", error);
    }
  };

  const checkCapsuleExists = async (props: any) => {
    try {
      const campanhaDevice: { collection: any[] } = await getCampaignId(
        props.dataResponse.campaignId
      );
      const filteredCam = campanhaDevice.collection.filter(
        c => c.fragranceId === props.fragranceId
      );
      if (!filteredCam.length) {
        Swal.fire({
          icon: "error",
          title: translate("screenApp_capsula_error"),
          text: translate("labels_fragrance_notCollection")
        }).then(() => {
          history.push("/admin/capsules");
        });
        return false;
      }

      const capsulesBydevice: any[] = await getCapsulesByDeviceId(
        props.deviceId
      );

      const checkCapsulebyDevice = capsulesBydevice.find(
        c => c.fragranceId === props.fragranceId
      );

      if (checkCapsulebyDevice) {
        if (checkCapsulebyDevice.serialNumber === props.serialNumber) {
          setEnableButtonSave(true);
          return true;
        }
        return false;
      }

      setEnableButtonSave(true);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  const getCapsule = async (serialNumber: string, fragranceId: number) => {
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `capsules/serial/${serialNumber}/${fragranceId}`
      });

      const dataResponse = await getDevices(response.deviceId);

      return { ...response, dataResponse };
    } catch (error) {
      return { status: fragranceId, serialNumber };
    }
  };

  const getDevices = async (deviceId: string) => {
    try {
      let devices: any[] = [];
      let page = 1;
      let totalPages = 1;

      while (page <= totalPages) {
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `devices?page=${page}`
        });

        devices = devices.concat(response.data);

        totalPages = response.totalPages || 1;
        page++;
      }

      const device = devices.find(
        (device: { deviceId: string }) => device.deviceId === deviceId
      );

      if (device) {
        return device;
      } else {
        return { status: "Device not found" };
      }
    } catch (error) {
      console.error("Erro ao buscar o dispositivo:", error);
      return { status: deviceId };
    }
  };

  const isValidDueDate = (dueDate: Date, capsuleStatus: string | null) => {
    const today = new Date().toISOString().split("T")[0];
    const dueDateFormatted = dueDate.toISOString().split("T")[0];

    if (capsuleStatus === "desativada" && dueDateFormatted !== today) {
      Swal.fire({
        icon: "error",
        title: translate("screenApp_capsula_error"),
        text: translate("labels_capsule_expirationToday")
      });
      return false;
    } else if (capsuleStatus === "ativada" && dueDateFormatted <= today) {
      Swal.fire({
        icon: "error",
        title: translate("screenApp_capsula_error"),
        text: translate("labels_capsule_laterToday")
      });
      return false;
    }

    return true;
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      let reqBase: any;
      reqBase = {
        method: "PUT",
        url: `/capsules/notremainingshots/${data.capsuleId}`
      };

      const dueDate = new Date(data.dueDate);
      if (!isValidDueDate(dueDate, capsuleStatus)) {
        setLoading(false);
        return;
      }

      const body: CapsuleI = {
        customerCode: data.customerCode,
        capsuleId: data.capsuleId,
        deviceId: data.deviceId,
        fragranceId: data.fragranceId,
        dueDate: data.dueDate,
        serialNumber: data.serialNumber,
        remainingShots: data.remainingShots,
        performedShots: data.performedShots || 1,
        deletedAt: capsuleStatus === "ativada" ? null : new Date().toISOString()
      };

      await api.makeHttpRequest({
        ...reqBase,
        data: body
      });

      Swal.fire({
        title: translate("defaultMessages.success"),
        text: translate("crudMessages.successText"),
        icon: "success"
      }).then(result => {
        if (result.isConfirmed) {
          history.push("/admin/capsules");
        }
      });
    } catch (e) {
      Swal.fire(
        translate(".defaultMessages.error"),
        translate(".screens.user.errors.register"),
        "error"
      );
    } finally {
      setAllowSave(true);
      setLoading(false);
    }
  };

  const handleReset = () => {
    reset({});
  };

  return (
    <div>
      <Card className="mt-3">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body>
            <Row style={{ marginBottom: 10, alignItems: "center" }}>
              <Col>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Avatar
                    src={valueFragrance?.fragrance?.image?.tempUri}
                    alt={valueFragrance?.fragrance?.name}
                    sx={{ width: 56, height: 56 }}
                  />
                  <Typography variant="body1">
                    {translate("screens_collectionDevice_table_slot")}:{" "}
                    {valueFragrance?.slot} - {valueFragrance?.fragrance?.name}
                  </Typography>
                </Box>
              </Col>
            </Row>
            <Row>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>{translate("labels_capsule_status")}</Form.Label>
                <Controller
                  name="deletedAt"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Radio
                          color="success"
                          checked={capsuleStatus === "ativada"}
                          onChange={() => {
                            onChange(null);
                            setCapsuleStatus("ativada");
                          }}
                          value="ativada"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "Ativada" }}
                        />
                        <Typography>{translate("labels_activated")}</Typography>
                        <Radio
                          color="success"
                          checked={capsuleStatus === "desativada"}
                          onChange={() => {
                            onChange(new Date().toISOString());
                            setCapsuleStatus("desativada");
                          }}
                          value="desativada"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "Desativada" }}
                        />
                        <Typography>{translate("labels_disabled")}</Typography>
                      </Stack>
                    </Stack>
                  )}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>{translate("labels_code")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={translate("labels_code")}
                  disabled
                  {...register("customerCode")}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screens.capsule.labels.serial")} *
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength={9}
                  minLength={9}
                  disabled
                  placeholder={translate("screens.capsule.labels.serial")}
                  {...register("serialNumber")}
                />
                {errors.serialNumber && (
                  <span className="invalid-input">
                    {errors.serialNumber.message}
                  </span>
                )}
              </Form.Group>

              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>{translate("screens_device_title")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={translate("screens_device_title")}
                  disabled
                  {...register("macAddress")}
                />
              </Form.Group>

              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screens_quiz_list_campaign")}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={translate("screens_quiz_list_campaign")}
                  disabled
                  {...register("campaignName")}
                />
              </Form.Group>

              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screens.capsule.labels.dueDate")}
                </Form.Label>

                <Controller
                  name="dueDate"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Form.Control
                      type="date"
                      //min={new Date().toISOString().split("T")[0]}
                      value={value ? value.split("T")[0] : value}
                      onChange={(value: any) => onChange(value.target.value)}
                    />
                  )}
                />
                {errors.dueDate && (
                  <span className="invalid-input">
                    {errors.dueDate.message}
                  </span>
                )}
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screens_capsule_labels_remaining")}
                </Form.Label>
                <Form.Control
                  type="number"
                  maxLength={9}
                  minLength={9}
                  placeholder={translate("screens_capsule_labels_remaining")}
                  {...register("remainingShots")}
                />
              </Form.Group>
            </Row>
            <Divider color="error" />
          </Card.Body>
          <FormActions
            module="capsules"
            onReset={handleReset}
            disableSubmit={!enableButtonSave}
          />
        </Form>
      </Card>
      <Loading isLoading={loading} />
    </div>
  );
};

export default CapsuleUpdate;
