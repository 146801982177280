import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon } from "@mui/material";

class headerButton extends React.Component {
  render() {
    const { label, onClick, path, icone, disabled } = this.props;
    return (
      <div>
        {path ? (
          <Link to={path} style={{ marginRight: "5px" }}>
            <Button
              variant="contained"
              color="primary"
              className="kt-button-adicionar"
              disabled={disabled}
            >
              <Icon className="mr-2 kt-icons">{icone ? icone : "add"}</Icon>{" "}
              {label ? label : "Adicionar"}
            </Button>
          </Link>
        ) : (
          <div style={{ marginRight: "5px" }}>
            <Button
              variant="contained"
              color="primary"
              className="kt-button-adicionar"
              disabled={disabled}
              onClick={onClick}
            >
              <Icon className="mr-2 kt-icons">{icone ? icone : "add"}</Icon>{" "}
              {label ? label : "Adicionar"}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default headerButton;
