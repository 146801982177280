import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { AnanseApiService } from "../../../../../services/Ananse/AnanseApiService";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350
    }
  }
};

const api = new AnanseApiService();
interface Props {
  url: string;
  onSelect: (value: any) => void;
  value?: any;
  placeholder: any;
  multiple?: boolean;
}
const NewSelectItem = ({
  url,
  onSelect,
  value: getvalue,
  placeholder,
  multiple
}: Props) => {
  const [data, setData] = React.useState<Array<any>>([]);
  const [selectedItem, setSelectItem] = React.useState("0");
  const [loading, setLoading] = React.useState(false);

  const getListFromValue = async () => {
    setLoading(true);
    let searchedList: any = [];
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/${url}`
      });
      if (response.length) {
        response.forEach((item: any) => {
          searchedList.push(item);
        });
      }
    } catch (error) {
      console.error(`Erro ao fazer a busca: ${error}`);
    } finally {
      setData(searchedList);
      setLoading(false);
      return searchedList;
    }
  };

  useEffect(() => {
    start();
  }, [getvalue]);

  const start = async () => {
    const result: Array<any> = await getListFromValue();
    if (getvalue && selectedItem === "0") {
      const filter = result.find(value => value.quizId === getvalue);
      setSelectItem(filter?.quizId);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSelectItem(event.target.value);
    onSelect(event.target.value);
  };

  if (!multiple) {
    return (
      <>
        <FormControl sx={{ width: "100%" }} fullWidth>
          <Form.Label>{placeholder}</Form.Label>
          <Select
            displayEmpty
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
            placeholder={placeholder}
            style={{ height: 38.4 }}
            disabled={loading}
            value={selectedItem}
            onChange={handleChange}
          >
            <MenuItem value={0}>
              <em>None</em>
            </MenuItem>
            {data.map(item => {
              return (
                <MenuItem key={item.quizId} value={item.quizId}>
                  {item.name}
                </MenuItem>
              );
            })}
            {/* <Link to='anamnese/create'>Criar anamnese</Link> */}
          </Select>
        </FormControl>
      </>
    );
  }
};

export default NewSelectItem;
