import React, { ReactNode } from "react";
import { Modal } from "@mui/material";
import styled from "styled-components";

const CustomModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`;

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  /* height: 100%; */

  position: relative;
  margin: auto;
  outline: none;

  /* Estilos adicionais para o container do modal */
  background-color: #ffffff; /* Fundo branco */
  border-radius: 10px; /* Bordas arredondadas */
  padding: 20px; /* Espaçamento interno */
`;

type ModalComponentProps = {
  open: boolean;
  children: ReactNode;
  container?: boolean
};
const ModalComponent: React.FC<ModalComponentProps> = ({ open, children,container }) => {
  if (!container) {
    return (
      <CustomModal open={open}>
        <>{children}</>
      </CustomModal>
    );
  }
  return (
    <CustomModal open={open}>
      <ModalContainer>{children}</ModalContainer>
    </CustomModal>
  );
};

export default ModalComponent;
