import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import { Form, Col, Table, Row } from "react-bootstrap";
import withFormValidation from "../../../../hoc/withFormValidation";
import SelectSearchComponent from "../../../auth/Components/Select2";
import { AppInput } from "../../../../partials/form";
import { IconButton, Button } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { connect } from "react-redux";
import translate from "@noar-health/commons";
import { FragranceApiService } from "../../../../services/Ananse/FragranceApiService";
import Swal from "sweetalert2";
import "./style.scss";
import { TranslationContext } from "_metronic/i18n/language";

const colors = {
  configured: "#5578EB",
  notConfigured: "#5A6268",
  selected: "#CE8300"
};

class CollectionDevice extends React.Component {

  static contextType = TranslationContext;

  constructor(props) {
    super(props);

    this.state = {
      translate: null,
      selectedIndex: props.defaultIndex
    };

    this.fragranceApi = new FragranceApiService();
    this.handleChange = this.handleChange.bind(this);
    this.updateConfiguration = this.updateConfiguration.bind(this);
    this.initializeTranslation();
  }

  async initializeTranslation() {
    // Aguarde até que o contexto seja definido
    const translate = await this.context;
    this.setState({ translate });
  }

  handleChange(e) {
    const collection = [...this.props.collection];
    collection[this.state.selectedIndex][e.target.name] = e.target.value;
    this.props.updateCollection(collection);
  }

  buildSlots() {
    const slots = [];
    for (let i = 0; i < this.props.collection.length; i++) {
      const c = this.props.collection[i];

      const body = {
        title: "",
        value: 1,
        color: colors.notConfigured,
        label: c.slot
      };

      if (
        c.fragrance &&
        c.shotSeconds &&
        c.expirationDays &&
        !c.fragranceDuplicated
      ) {
        body.title = c.fragrance.name;
        body.color = colors.configured;
      }

      if (this.state.selectedIndex === i) {
        body.color = colors.selected;
      }

      slots.push(body);
    }

    return slots;
  }

  updateConfiguration() {
    if (!this.props.validateForm()) {
      return;
    }

    const currentSlot = this.props.collection[this.state.selectedIndex];
    const slotWithSameFragrance = this.props.collection.filter(
      c =>
        c.fragrance &&
        c.fragrance.fragranceId === currentSlot.fragrance.fragranceId
    );

    if (slotWithSameFragrance.length > 1) {
      return this.handleChange({
        target: {
          name: "fragranceDuplicated",
          value: true
        }
      });
    }

    if (currentSlot.fragranceDuplicated) {
      this.handleChange({
        target: {
          name: "fragranceDuplicated",
          value: false
        }
      });
    }

    const nextIndex =
      this.state.selectedIndex === this.props.collection.length - 1
        ? 0
        : this.state.selectedIndex + 1;

    this.setState({ selectedIndex: nextIndex });
  }

  async clearSlot(slot) {
    this.initializeTranslation();
    const collection = [...this.props.collection];
    const index = collection.findIndex(c => c.slot === slot);

    const hasAnswer = await this.checkAnyAnswer(collection[index]);
    if (hasAnswer) {
      Swal.fire(
        this.state.translate("defaultMessages.error"),
        this.state.translate("screens.collectionDevice.validate.hasAnswer"),
        "error"
      );

      return;
    }

    delete collection[index].fragrance;
    this.props.updateCollection(collection);
  }

  async checkAnyAnswer(collection) {
    const { fragranceId, campaignId } = collection;
    const reqBase = {
      method: "GET",
      url: `has-answer/${fragranceId}/${campaignId}`
    };

    const response = await this.fragranceApi.makeHttpRequest({
      ...reqBase
    });

    return response;
  }

  render() {
    const translate = this.context;
    const slots = this.buildSlots();
    const lineWidth = 15;

    const slotConfig = this.props.collection[this.state.selectedIndex] || {};
    const configuredFragrances = this.props.collection
      .filter(
        c =>
          c.hasOwnProperty("fragrance") &&
          slotConfig &&
          c.slot !== slotConfig.slot
      )
      .sort((a, b) => a.slot - b.slot);

    const validationMessage = slotConfig
      ? this.props.validator.message(
          "fragranceId",
          slotConfig.fragrance,
          "required"
        )
      : null;

    const disableForm = this.state.selectedIndex === null;

    const center = [45, 60];
    const viewBoxSize = [100, 125];
    if (window.innerWidth < 650) {
      center[0] = 50;
      viewBoxSize[1] = 170;
    }

    return (
      <Row className="collection-device">
        <Form.Group as={Col} lg={"5"} xs="12" className="device">
          <ul className="legends mt-4">
            <li>
              <span className="configured" />{" "}
              {translate("screens.collectionDevice.legend.configured")}
            </li>
            <li>
              <span className="not-configured" />{" "}
              {
                translate("screens.collectionDevice.legend.notConfigured")
              }
            </li>
            <li>
              <span className="selected" />{" "}
              {translate("screens.collectionDevice.legend.selected")}
            </li>
          </ul>
          <PieChart
            data={slots}
            lineWidth={lineWidth}
            paddingAngle={5}
            segmentsStyle={{ cursor: "pointer" }}
            label={({ dataEntry }) => dataEntry.label}
            labelStyle={index => ({
              fontSize: "5px"
            })}
            labelPosition={110}
            center={center}
            viewBoxSize={viewBoxSize}
            onClick={(_, index) => {
              this.setState({ selectedIndex: index });
            }}
          />
        </Form.Group>

        <Form.Group as={Col} lg="7" xs="12" className="mt-3">
          <Row>
            <div className="collectionContainer">
              <div className="collectionDevice">
                <label>
                  {
                    translate("screens.collectionDevice.labels.fragrance")
                  }
                </label>
                <SelectSearchComponent
                  handleChange={this.handleChange}
                  name="fragrance"
                  className="kt-width-full"
                  disabled={disableForm}
                  label={
                    translate("screens.collectionDevice.placeholders.fragrance")
                  }
                  resetInput={!slotConfig.fragrance}
                  url={`/fragrances/quick-search?str=`}
                  convertObject={obj => ({
                    id: obj.fragranceId,
                    value: obj.fragranceId,
                    label: obj.name,
                    target: {
                      value: obj,
                      name: "fragrance"
                    }
                  })}
                  value={
                    slotConfig.fragrance
                      ? { label: slotConfig.fragrance.name }
                      : undefined
                  }
                  translate={translate}
                />
                {validationMessage}
                {!validationMessage && slotConfig.fragranceDuplicated ? (
                  <div className="kt-error-label text-left">
                    {
                      translate("screens.collectionDevice.validate.duplicated")
                    }
                  </div>
                ) : null}
              </div>
              <div className="collectionDevice">
                <label>
                  {
                    translate("screens.collectionDevice.legend.shotSeconds")
                  }
                </label>
                <AppInput
                  type="number"
                  name="shotSeconds"
                  placeholder={
                    translate("screens.collectionDevice.placeholders.shotSeconds")
                  }
                  min="1"
                  disabled={disableForm}
                  value={slotConfig.shotSeconds || ""}
                  onChange={this.handleChange}
                  validator={this.props.validator}
                  validations={"required|numeric"}
                />
              </div>
              <div className="collectionDevice">
                <label>
                  {
                    translate("screens.collectionDevice.legend.expirationDays")
                  }
                </label>
                <AppInput
                  type="number"
                  name="expirationDays"
                  placeholder={
                    translate("screens.collectionDevice.placeholders.expirationDays")
                  }
                  disabled={disableForm}
                  value={slotConfig.expirationDays || ""}
                  onChange={this.handleChange}
                  // validator={this.props.validator}
                  // validations={"required|numeric"}
                />
              </div>
              <div>
                <Button
                  variant="contained"
                  className="mt-4"
                  style={{ color: "#fff", backgroundColor: "#677a88" }}
                  disabled={disableForm}
                  onClick={this.updateConfiguration}
                >
                  {translate("defaultMessages.ok")}
                </Button>
              </div>
            </div>
          </Row>
          <Row>
            {this.props.hasDeviceError && (
              <div className="mb-3">
                <div className="kt-error-label text-left">
                  {
                    translate("screens.collectionDevice.validate.deviceError")
                  }
                </div>
              </div>
            )}

            <Table striped bordered responsive>
              <thead className="table-light">
                <tr className="text-uppercase">
                  <th className="font-weight-bold" width="10%">
                    {translate("screens.collectionDevice.table.slot")}
                  </th>
                  <th className="font-weight-bold" width="40%">
                    {
                      translate("screens.collectionDevice.table.fragrance")
                    }
                  </th>
                  <th className="font-weight-bold" width="20%">
                    {
                      translate("screens.collectionDevice.table.shotSeconds")
                    }
                  </th>
                  <th className="font-weight-bold" width="20%">
                  {
                      translate("screens.collectionDevice.table.expirationDays")
                    }
                  </th>
                  <th className="font-weight-bold" width="10%"></th>
                </tr>
              </thead>
              <tbody>
                {configuredFragrances.length ? (
                  configuredFragrances.map(c => (
                    <tr
                      key={c.slot}
                      className={c.fragranceDuplicated ? "text-danger" : ""}
                    >
                      <td>{c.slot}</td>
                      <td>
                        {c.fragrance.image && (
                          <img
                            className="fragrance-preview"
                            src={c.fragrance.image.tempUri}
                            alt={c.fragrance.name}
                          />
                        )}

                        {c.fragrance.name}
                      </td>
                      <td>{c.shotSeconds}</td>
                      <td>{c.expirationDays}</td>
                      <td>
                        <IconButton
                          size="small"
                          title={translate("datatable.delete")}
                          onClick={() => this.clearSlot(c.slot)}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-center">
                    <td colSpan={4}>
                      {
                        translate("screens.collectionDevice.table.nothingConfig")
                      }
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Row>
        </Form.Group>
      </Row>
    );
  }
}

function mapStateToProps(store) {
  return {
    translate: translate(store.i18n.lang)
  };
}
export default connect(mapStateToProps)(withFormValidation(CollectionDevice));
