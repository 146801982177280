import React from "react";
import { Form } from 'react-bootstrap';

class AppInput extends React.Component {
  render() {
    let message = null;
    if (this.props.validations) {
      message = this.props.validator.message(
        this.props.name,
        this.props.value,
        this.props.validations
      );
    }

    const newProps = {
      type: this.props.type || undefined,
      as: this.props.as || undefined,
      placeholder: this.props.placeholder || undefined,
      autoComplete: this.props.autoComplete || 'off',
      name: this.props.name || undefined,
      value: this.props.value,
      onChange: this.props.onChange,
      onKeyDown: this.props.onKeyDown || undefined,
      onBlur: this.props.onBlur || undefined,
      className: `${this.props.className || ""} ${message && "border-danger"}`,
      readOnly: this.props.readOnly || false,
      disabled: this.props.disabled || false,
      id: this.props.id || undefined,
      checked: this.props.checked || false,
      maxLength: this.props.maxLength || undefined,
      max: this.props.max || undefined,
    };

    let children = null;
    if (this.props.children) {
      children = React.Children.map(this.props.children, (child) => {
        return React.cloneElement(child, newProps);
      });
    }

    return (
      <>
        {children
          ? children
          : <Form.Control {...newProps} />
        }

        {message}
      </>
    );
  }
}

export default AppInput;
