import { Button, Stack } from "@mui/material";
import { useTranslation } from "_metronic/i18n/language";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

interface Props {
  publishedAt?: any;
  question?: any;
  questions?: any;
  validatedAt?: any;
  createQuestion?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onCleanForm?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  publishQuiz?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  validateQuiz?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}
const ButtonsQuiz: React.FC<Props> = ({
  publishedAt,
  question,
  questions,
  validatedAt,
  createQuestion,
  onCleanForm,
  publishQuiz,
  validateQuiz
}) => {
  const translate = useTranslation();
  return (
    <Form.Group as={Col} lg="12" xs="12" className="mt-3 pt-2">
      <div className="text-left">
        <Stack
          sx={{ marginTop: "30px" }}
          justifyContent="flex-end"
          alignItems="flex-end"
          direction={{ xs: "column", sm: "row" }}
          spacing={1}
        >
          <Button
            variant="contained"
            color="success"
            onClick={createQuestion}
            className="mr-3"
          >
            {question.quizQuestionId
              ? translate("screens.quiz.labels.updateQuestion")
              : translate("screens.quiz.labels.saveQuestion")
              }
          </Button>

          <Button
            variant="contained"
            color="warning"
            onClick={onCleanForm}
            className="mr-3"
          >
            {translate("buttons_clean")}
          </Button>

          <Link to={`/admin/quiz`} className="mr-3">
            <Button variant="contained" color="error" type="button">
              {translate("buttons_cancel")}
            </Button>
          </Link>

          <Button
            variant="contained"
            color="info"
            onClick={validateQuiz}
            className="mr-3"
            disabled={validatedAt !== null || questions.length === 0}
          >
            {translate("screens.quiz.labels.validate")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={publishQuiz}
            className="mr-3"
            disabled={validatedAt === null || publishedAt !== null}
          >
            {translate("screens.quiz.labels.publish")}
          </Button>
        </Stack>
      </div>
    </Form.Group>
  );
};

export default ButtonsQuiz;
