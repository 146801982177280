import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  IconButton,
  Typography,
  Box,
  Grid
} from "@mui/material";
import { Form, Spinner, Image } from "react-bootstrap";
import { QuestionApiService } from "app/services/Ananse/QuestionApiService";
import CloseIcon from "@mui/icons-material/Close";
import { AnswerType, FileType } from "app/types";

interface ImageUploadProps {
  answers: AnswerType[];
  answersOld: AnswerType[];
  open: boolean;
  onClose: () => void;
}

const questionService = new QuestionApiService();

const ModalAddImageAnswer: React.FC<ImageUploadProps> = ({
  answers,
  answersOld,
  open,
  onClose
}) => {
  const [files, setFiles] = useState<{
    [x: number]: FileType;
  }>({});
  const [previews, setPreviews] = useState<{ [key: number]: string | null }>(
    {}
  );
  const [uploadProgress, setUploadProgress] = useState<{
    [key: number]: number;
  }>({});
  const [isUploading, setIsUploading] = useState<boolean[]>([]);
  const [overallProgress, setOverallProgress] = useState<number>(0);
  const [hasNewImages, setHasNewImages] = useState<boolean>(false);

  const handleClose = () => {
    setIsUploading([]);
    setFiles({});
    setPreviews({});
    setOverallProgress(0);
    setHasNewImages(false);
    onClose();
  };

  const getFilesByFileOwnerId = async (
    fileOwnerId: string
  ): Promise<FileType | undefined> => {
    try {
      const response = await questionService.getFileByFileOwnerId(fileOwnerId);
      return response[0];
    } catch (error) {
      console.log("geFilesByFileOwnerId: ", error);
    }
  };

  const handleFileChange = (
    event: React.ChangeEvent<any>,
    quizQuestionAnswerId: number
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const prepareData: FileType = {
        file
      };
      const body = { ...files, [quizQuestionAnswerId]: prepareData };
      setFiles(body);
      setPreviews({
        ...previews,
        [quizQuestionAnswerId]: URL.createObjectURL(file)
      });
      setHasNewImages(true);
    }
  };

  const calculateOverallProgress = (progress: { [key: number]: number }) => {
    const total = answers.length;
    const sumProgress = Object.values(progress).reduce(
      (sum, value) => sum + value,
      0
    );
    setOverallProgress(Math.round(sumProgress / total));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    try {
      event.preventDefault();
      for (const answer of answers) {
        const file = files[answer.quizQuestionAnswerId];

        // Se não houver novo arquivo selecionado, pular o envio
        if (!file) {
          continue;
        }

        const fileOwnerId = answer.quizQuestionAnswerId.toString();
        const formData = new FormData();

        if (file.file) {
          formData.append("file", file.file);
        } else if (file.fileId && file.tempUri) {
          formData.append("fileId", file.fileId.toString());
          formData.append("tempUri", file.tempUri);
          formData.append("path", file.path || "");
          formData.append("downloads", file.downloads?.toString() || "0");
        }

        formData.append("fileOwnerId", fileOwnerId);

        setIsUploading(prev => ({
          ...prev,
          [answer.quizQuestionAnswerId]: true
        }));

        try {
          // Método para cadastrar o arquivo
          const reqBase = { method: "POST", url: "questionanswerfile" };

          await questionService.makeHttpRequest({
            ...reqBase,
            data: formData,
            onUploadProgress: (progressEvent: ProgressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(prev => {
                const updatedProgress = {
                  ...prev,
                  [answer.quizQuestionAnswerId]: progress
                };
                calculateOverallProgress(updatedProgress);
                return updatedProgress;
              });
            }
          });

          console.log(
            `File uploaded successfully for answer ${answer.quizQuestionAnswerId}`
          );
        } catch (error) {
          console.error(
            `Error uploading file for answer ${answer.quizQuestionAnswerId}:`,
            error
          );
        } finally {
          setIsUploading(prev => ({
            ...prev,
            [answer.quizQuestionAnswerId]: false
          }));
        }
      }
    } catch (error) {
      //
    } finally {
      // Após o envio, redefinir o estado de novas imagens
      setHasNewImages(false);
      setOverallProgress(0);
      onClose();
    }
  };

  useEffect(() => {
    const fetchExistingImages = async () => {
      const previewsMap: { [key: number]: string | null } = {};
      const filesMap: { [x: number]: FileType } = {};

      for (const answer of answersOld) {
        const newAnswer = answers.find(a => a.answerId === answer.answerId);
        if (newAnswer) {
          const image = await getFilesByFileOwnerId(
            answer.quizQuestionAnswerId.toString()
          );
          if (image?.tempUri) {
            previewsMap[newAnswer.quizQuestionAnswerId] = image.tempUri;
            filesMap[newAnswer.quizQuestionAnswerId] = image;
          }
        }
      }

      setPreviews(previewsMap);
    };

    fetchExistingImages();
  }, [answers, answersOld, open]);

  useEffect(() => {
    return () => {
      Object.values(previews).forEach(url => {
        if (url) {
          URL.revokeObjectURL(url);
        }
      });
    };
  }, [previews]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Enviar Imagem para Respostas
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Form onSubmit={handleSubmit}>
          {answers.map(answer => (
            <Box key={answer.quizQuestionAnswerId} mb={3}>
              <Typography variant="h6">
                Escolha uma imagem para a resposta {answer.desc}
              </Typography>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={8}>
                  <Form.Group
                    controlId={`formFile-${answer.quizQuestionAnswerId}`}
                  >
                    <Form.Control
                      type="file"
                      onChange={event =>
                        handleFileChange(event, answer.quizQuestionAnswerId)
                      }
                      accept=".png, .jpg, .jpeg"
                    />
                  </Form.Group>
                </Grid>
                <Grid item xs={4}>
                  {previews[answer.quizQuestionAnswerId] && (
                    <Image
                      src={previews[answer.quizQuestionAnswerId]!}
                      alt={`Preview of answer ${answer.desc}`}
                      thumbnail
                      width={150}
                      height={150}
                    />
                  )}
                </Grid>
              </Grid>
              {isUploading[answer.answerId] && (
                <Box mt={2}>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress[answer.answerId] || 0}
                  />
                  <Typography variant="body2" color="textSecondary">
                    {uploadProgress[answer.answerId] || 0}%
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
          {Object.values(isUploading).some(uploading => uploading) && (
            <Box mt={3}>
              <Typography variant="h6">Progresso Geral</Typography>
              <LinearProgress variant="determinate" value={overallProgress} />
              <Typography variant="body2" color="textSecondary">
                {overallProgress}%
              </Typography>
            </Box>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={
            Object.values(isUploading).some(uploading => uploading) ||
            !hasNewImages
          }
        >
          {Object.values(isUploading).some(uploading => uploading) ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              {" Enviando..."}
            </>
          ) : (
            "Enviar"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddImageAnswer;
