import { Button, Stack } from "@mui/material";
import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { UseSelectorI } from "interfaces";
import { useTranslation } from "_metronic/i18n/language";

interface Props {
  module: string;
  onCancel?: () => void;
  onCreateEdit?: () => void;
  onReset?: () => void;
  disableSubmit?: boolean;
}
const FormActions = ({
  module,
  onCancel,
  onCreateEdit,
  onReset,
  disableSubmit
}: Props) => {
  const translate = useTranslation()
  return (
    <Card.Footer
      style={{
        backgroundColor: "#fff",
        textAlign: "end"
      }}
      className="border-0"
    >
      <Stack
        sx={{ marginTop: "3px" }}
        justifyContent="flex-end"
        alignItems="flex-end"
        direction={{ xs: "column", sm: "row" }}
        spacing={1}
      >
        {onCancel ? (
          <Button
            variant="contained"
            color="error"
            onClick={onCancel}
            className="mr-3"
          >
            {translate("buttons.cancel")}
          </Button>
        ) : (
          <Link to={`/admin/${module}`}>
            <Button
              variant="contained"
              color="error"
              type="button"
              className="mr-3"
            >
              {translate("buttons.cancel")}
            </Button>
          </Link>
        )}

        <Button
          variant="contained"
          color="warning"
          onClick={onReset}
          className="mr-3"
          type="reset"
        >
          {translate("buttons.clean")}
        </Button>
        <Button
          variant="contained"
          color="success"
          className="btb-success"
          type="submit"
          disabled={disableSubmit}
        >
          {translate("buttons.save")}
        </Button>
      </Stack>
    </Card.Footer>
  );
};

export default FormActions;
