import axios from "axios";
import { QuestionApiService } from "./Ananse/QuestionApiService";
import { QuestionType } from "app/types";

const questionService = new QuestionApiService();
export const uploadFileToAzureBlob = async (file: File): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await axios.post(
      "https://noar-localise-api.azurewebsites.net/api/azure-blob/upload?directory=answer",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );

    // Assume that the response contains the path or URL to the uploaded file
    console.log("Uploading file: ", response.data);
    return response.data.filePath; // Ajuste conforme a estrutura da resposta da API
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error("File upload failed");
  }
};

export const addUpdate = async (data: QuestionType) => {
  try {
    const body = {
      quizId: data.quizId,
      quizQuestionId: data.quizQuestionId || 0,
      questionId: data.questionId || 0,
      quest: data.quest || "",
      fragranceId: data.fragranceId || "",
      rightAnswer: data.rightAnswer || "",
      type: data.type || "",
      arrangeQuestionSequence: data.arrangeQuestionSequence,
      answers: data.answers.map(answer => ({
        quizQuestionAnswerId: answer.quizQuestionAnswerId || 0,
        answerId: answer.answerId || 0,
        desc: answer.desc || "",
        description: answer.description || "",
        nextQuizQuestionId: answer.nextQuizQuestionId || 0
      }))
    };

    console.log("question.onSubmit: ", body);

    const reqBase = { method: "POST", url: "add-update" };
    const response = await questionService.makeHttpRequest({
      ...reqBase,
      data: body
    });

    console.log("Response data:", response);
    return response;
  } catch (error) {
    console.error("Error during the addUpdate request:", error);
    throw new Error("Request failed");
  }
};
