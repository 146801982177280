import { Form, Col } from "react-bootstrap";
import IsValidName from "../../../../../utils/validators/IsValidName";

type FormControlProps = {
  value: string;
  name: string;
  label: string;
  placeholder?: string;
  lg?: string;
  onChange: (e: any) => void;
};

const FormControl = ({
  value,
  name,
  label,
  placeholder,
  lg = "4",
  onChange
}: FormControlProps) => {
  return (
    <Form.Group as={Col} lg={lg} xs="12">
      {/* <label className="form-label">{label} *</label> */}
      <Form.Label className="form-label">{label} *</Form.Label>
      <Form.Control
        type="text"
        autoComplete="off"
        name={name}
        placeholder={placeholder || value}
        maxLength={100}
        value={value}
        isInvalid={!!value && !IsValidName(value)}
        onChange={onChange}
        required
      />
    </Form.Group>
  );
};

export default FormControl;
