import React from "react";
import UsersIcon from "@mui/icons-material/Group";
import DevicesIcon from "@mui/icons-material/Devices";
import QuizIcon from "@mui/icons-material/Quiz";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import GroupsIcon from "@mui/icons-material/Groups";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CampaignIcon from "@mui/icons-material/Campaign";
import CapsulesIcon from "@mui/icons-material/GridOn";
import CapsulesIcon2 from "@mui/icons-material/ViewCompact";
import CategoryIcon from "@mui/icons-material/Category";
import { useRTL } from "themes/RTLProvider/RTLProvider";

interface IconProps {
  iconName: string;
}

const IconComponent: React.FC<IconProps> = ({ iconName }) => {
  const isRtl = useRTL();
  // const arrowIcon =
  let Icon: React.ElementType;

  // Mapeia os nomes dos ícones para os componentes de ícones correspondentes
  const iconMap: { [key: string]: React.ElementType } = {
    users: UsersIcon,
    devices: DevicesIcon,
    quiz: QuizIcon,
    lineAxis: LineAxisIcon,
    switchAccount: SwitchAccountIcon,
    groups: GroupsIcon,
    import: FileUploadIcon,
    campaign: CampaignIcon,
    capsules: CapsulesIcon2,
    fragrances: CategoryIcon
  };

  // Verifica se o nome do ícone fornecido existe no mapeamento
  // Se existir, atribui o componente de ícone correspondente
  if (iconMap[iconName]) {
    Icon = iconMap[iconName];
  } else {
    // Se o nome do ícone fornecido não existir no mapeamento,
    // você pode lidar com isso de acordo com sua lógica, como renderizar um ícone de erro padrão
    return isRtl ? (
      <SubdirectoryArrowLeftIcon />
    ) : (
      <SubdirectoryArrowRightIcon />
    );
  }

  return <Icon />;
};

export default IconComponent;
