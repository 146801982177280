import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MakeGetDeviceType, makeGetDevice } from "app/factories";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "_metronic/i18n/language";
import FormActions from "components/FormActions";
import {
  CampaignType,
  getAllCampaigns,
  updateCampaignOnDevice,
  updateDeviceById
} from "app/crud";
import { SelectCampaigns } from "./components/SelectCampaigns";
import Loading from "app/pages/home/components/Loading";
import Swal from "sweetalert2";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Stack, Typography } from "@mui/material";

type ParamsType = {
  macAddress?: string;
};

export type CampaignIdType = {
  campaignId: number;
  isDisplay: boolean;
};

export const CAMPAIGN_ID_FIELD = "campaignId";
export const IS_DISPLAY_FIELD = "isDisplay";

const DeviceUpdate: React.FC = () => {
  const [device, setDevice] = useState({} as MakeGetDeviceType);
  const [campaign, setCampaign] = useState<CampaignType[]>([]);
  const [loading, setLoading] = useState(true);

  const translate = useTranslation();
  const history = useHistory();
  const { macAddress } = useParams<ParamsType>();

  const { register, handleSubmit, setValue, watch } = useForm<CampaignIdType>();

  if (!macAddress) {
    return <div>DEVICE NÃO ENCONTRADO</div>;
  }

  useEffect(() => {
    const start = async () => {
      const data = await makeGetDevice(macAddress);
      setDevice(data);
      setValue(CAMPAIGN_ID_FIELD, data.campaignId);
      setValue(IS_DISPLAY_FIELD, data.isDisplay);
      setLoading(false);
    };
    getAllCampaigns().then(data => {
      setCampaign(data);
    });
    start();
  }, []);

  const onSubmit: SubmitHandler<FieldValues> = async data => {
    setLoading(true);
    try {
      const success = await updateCampaignOnDevice(
        device?.deviceId,
        data.campaignId
      );
      const success2 = await updateDeviceById(device?.deviceId, {
        isDisplay: data.isDisplay
      });
      if (!success) {
        throw new Error();
      }
      if (!success2) {
        Swal.fire(
          translate("defaultMessages.error"),
          translate("screens_device_errors_display"),
          "error"
        );
        throw new Error();
      }
      Swal.fire({
        title: translate("defaultMessages.success"),
        text: translate("crudMessages.successText"),
        icon: "success"
      }).then(result => {
        if (result.isConfirmed) {
          history.push("/admin/devices");
        }
      });
    } catch (e) {
      Swal.fire(
        translate("defaultMessages.error"),
        translate("screens_device_errors_register"),
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const isDisplayValue = watch(IS_DISPLAY_FIELD);

  return (
    <div>
      <Card className="mt-3">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body>
            <Row>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screens_device_labels_macAddress")}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={device?.macAddress}
                  disabled
                />
              </Form.Group>
              <SelectCampaigns
                className="form-select"
                data={campaign}
                register={register}
              />
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screens_device_labels_user")}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={device?.connectedUser}
                  disabled
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screens_device_labels_owner")}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={device?.ownerName}
                  disabled
                />
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Check
                  style={{ marginTop: 20 }}
                  type="checkbox"
                  label={`${translate("screens_device_display_title")} ${
                    isDisplayValue
                      ? translate("screenApp_modal_yes")
                      : translate("screenApp_modal_no")
                  }`}
                  {...register(IS_DISPLAY_FIELD)}
                  checked={isDisplayValue}
                  onChange={event =>
                    setValue(IS_DISPLAY_FIELD, event.target.checked)
                  }
                />
              </Form.Group>
            </Row>
          </Card.Body>
          <FormActions module="devices" onReset={() => null} />
        </Form>
      </Card>
      <Loading isLoading={loading} />
    </div>
  );
};
export default DeviceUpdate;
