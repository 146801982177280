import { Button, Stack } from "@mui/material";
import Loading from "app/pages/home/components/Loading";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import React, { useCallback, useEffect, useState } from "react";
import { InputMultFiles } from "./components";
import RenderJsons from "./components/RenderJsons";
import {
  AccountType,
  JsonResultType,
  QuizAnswerType,
  ValidadeResultType
} from "./types";
import { fetchAddress } from "./utils/geolocation";
import { useTranslation } from "_metronic/i18n/language";

const api = new AnanseApiService();

export const ImportScreen: React.FC = () => {
  const translate = useTranslation();
  const [loading, setLoading] = useState(false);
  const [checking, setChecking] = useState(false);
  const [saving, setSaving] = useState(false);
  const [valid, setValid] = useState<boolean | null>(null);
  const [validated, setValidated] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [jsonData, setJsonData] = useState<JsonResultType[]>([]);
  const [accounts, setAccounts] = useState<AccountType[]>([]);
  const [quizAnswers, setQuizAnswers] = useState<QuizAnswerType[]>([]);
  const [validationResults, setValidationResults] = useState<
    ValidadeResultType
  >({});
  const [
    validationResultsQuizAnswers,
    setValidationResultsQuizAnswers
  ] = useState<ValidadeResultType>({});
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!files.length) {
      setValidationResults({});
    }
  }, [files]);

  const getAccountByUserId = async (userId: number | undefined) => {
    try {
      if (userId) {
        const response: AccountType[] = await api.makeHttpRequest({
          url: `account/buscarporiduser/${userId}`
        });
        setAccounts(prevAccounts => [...prevAccounts, ...response]);
        return response || [];
      }
    } catch (error) {
      console.error("Error fetching account by user ID:", error);
      return [];
    }
  };

  const checkJsons = async (
    accountsByUserId: AccountType[],
    resultsByUserId: QuizAnswerType[]
  ) => {
    setChecking(true);
    // console.log("checkJsons 1");
    let updatedJsonData = [...jsonData];
    const results: { [key: string]: boolean } = {};
    const resultsQuizAnswers: { [key: string]: boolean } = {};
    let count = 0;
    for (let i = 0; i < jsonData.length; i++) {
      const data = jsonData[i];
      const accountId = data?.account?.accountId;
      // console.log("Account ID antes:", accountId);
      const testResultId = data.testeResult.testResultId.toLowerCase() || false;
      if (data?.account && accountsByUserId.length) {
        const account = accountsByUserId.some(a => a.accountId === accountId);
        results[accountId] = account;
      }
      // console.log("Account ID depois:", accountId);
      if (testResultId) {
        const answer = resultsByUserId.some(
          a => a.testResultId.toLowerCase() === testResultId
        );
        resultsQuizAnswers[testResultId] = answer;
        const quizAnswerPayload = [...data.testeResult.quizAnswer];
        // console.log("00");
        const address = await fetchAddress(quizAnswerPayload[0].location);
        // console.log("01", address);
        for (let j = 0; j < quizAnswerPayload.length; j++) {
          quizAnswerPayload[j].location = address;
        }
        updatedJsonData[i] = {
          ...updatedJsonData[i],
          testeResult: {
            ...updatedJsonData[i].testeResult,
            quizAnswer: quizAnswerPayload
          }
        };
      }
      count++;
      setProgress(Math.round((count / jsonData.length) * 100));
    }
    setJsonData(updatedJsonData);
    setValidationResults(results);
    setValidationResultsQuizAnswers(resultsQuizAnswers);
    setValid(false);
    setProgress(0);
    setChecking(false);
    // console.log("checkJsons 2");
  };

  const getResultsByAccountId = async (userId: number) => {
    try {
      const response: QuizAnswerType[] = await api.makeHttpRequest({
        url: `quiz/userid/${userId}`
      });
      setQuizAnswers(prevAnswers => [...prevAnswers, ...response]);
      return response;
    } catch (error) {
      console.error("Error fetching results:", error);
    }
  };

  const recorderData = async (): Promise<void> => {
    setLoading(true);

    const userIds = new Set<number>();
    for (let i = 0; i < jsonData.length; i++) {
      const userId = jsonData[i]?.account?.userId;
      if (userId !== null && userId !== undefined) {
        userIds.add(userId);
      }
    }

    let accountsByUserId: AccountType[] = [];
    let resultsByUserId: QuizAnswerType[] = [];

    if (userIds.size > 1) {
      // Se houver mais de um userId, chamar os gets para cada userId
      const promises: Promise<void>[] = [];

      for (let userId of userIds) {
        promises.push(
          getAccountByUserId(userId).then(data => {
            if (data) {
              accountsByUserId = accountsByUserId.concat(data);
            }
          })
        );
        promises.push(
          getResultsByAccountId(userId).then(data => {
            if (data) {
              resultsByUserId = resultsByUserId.concat(data);
            }
          })
        );
      }

      // Aguardar todas as promessas serem resolvidas
      await Promise.all(promises);
    } else if (userIds.size === 1) {
      // Se houver apenas um userId, chamar os gets uma vez
      const singleUserId = Array.from(userIds)[0];
      const accountData = await getAccountByUserId(singleUserId);
      if (accountData) {
        accountsByUserId = accountsByUserId.concat(accountData);
      }
      const resultsData = await getResultsByAccountId(singleUserId);
      if (resultsData) {
        resultsByUserId = resultsByUserId.concat(resultsData);
      }
    }

    // Chamar checkJsons após os gets com os dados obtidos
    await checkJsons(accountsByUserId, resultsByUserId);
    setLoading(false);
  };

  useEffect(() => {
    // console.log("files: ", files.length);
    if (files.length > 0) {
      recorderData();
    } else {
      setValidated(false);
    }
  }, [files]);

  const handleSave = async () => {
    setSaving(true);
    let updatedJsonData = [...jsonData];
    let count = 0;

    for (let i = 0; i < jsonData.length; i++) {
      const data = jsonData[i];
      const accountId = data?.account?.accountId;
      const testResultId =
        data?.testeResult?.testResultId?.toLowerCase() || false;
      let newAccountId = 0;

      if (data?.account && accounts.length) {
        const accountExists = accounts.some(a => a.accountId === accountId);

        if (!accountExists) {
          const payload = { ...data.account };
          const reqBase = {
            method: "POST",
            url: `account`,
            data: payload
          };

          const response = await api.makeHttpRequest(reqBase);
          newAccountId = response.accountId;
          // console.log("newAccountId: ", newAccountId);

          updatedJsonData[i] = {
            ...updatedJsonData[i],
            account: {
              ...updatedJsonData[i].account,
              accountId: newAccountId
            }
          };
          // console.log("updatedJsonData: ", updatedJsonData);
        }

        if (testResultId) {
          const answerExists = quizAnswers.some(
            a => a.testResultId.toLowerCase() === testResultId
          );
          // console.log("answer: ", answerExists);

          if (!answerExists) {
            // console.log("answer 1: ", answerExists);
            const quizAnswerPayload = [...data.testeResult.quizAnswer];

            for (let j = 0; j < quizAnswerPayload.length; j++) {
              if (newAccountId) {
                quizAnswerPayload[j].accountId = newAccountId;
                quizAnswerPayload[j].location = await fetchAddress(
                  quizAnswerPayload[j].location
                );
              }
            }

            const reqBase = {
              method: "POST",
              url: `quiz/quizanswer`,
              data: quizAnswerPayload
            };

            // console.log(
            //   "payload quizAnswer: ",
            //   JSON.stringify(quizAnswerPayload, null, 4)
            // );
            // Uncomment the lines below when you integrate the API
            const response = await api.makeHttpRequest(reqBase);
            // console.log("response quizAnswer: " + JSON.stringify(response));

            // Update the jsonData with the new quiz answers
            updatedJsonData[i] = {
              ...updatedJsonData[i],
              testeResult: {
                ...updatedJsonData[i].testeResult,
                quizAnswer: quizAnswerPayload
              }
            };
          }
        }
      }
      count++;
      setProgress(Math.round((count / jsonData.length) * 100));
    }

    setJsonData(updatedJsonData); // Update the state with the modified jsonData
    recorderData();
    // console.log("Count: " + count);
    setProgress(0);
    setSaving(false);
  };

  // console.log("Checking: ", checking);
  // console.log("Loading: ", loading);
  // console.log("progress: ", progress);
  // console.log("valid: ", accounts);

  return (
    <div className="mt-3">
      <Loading isLoading={loading || checking || saving} progress={progress} />
      <InputMultFiles
        files={files}
        setFiles={setFiles}
        setJsonData={setJsonData}
        setValid={setValid}
      />
      <Stack flexDirection="row" flex={1}>
        {!!files.length && (
          <Button
            variant="contained"
            color="info"
            style={{ flex: 1, margin: "10px" }}
            onClick={async () => {
              await recorderData();
              setValidated(true);
            }}
          >
            {translate("buttons_validateJson")}
          </Button>
        )}
        {!!files.length && (
          <Button
            variant="contained"
            color="inherit"
            disabled={!validated}
            style={{ flex: 1, margin: "10px" }}
            onClick={handleSave}
          >
            {translate("buttons_syncJsons")}
          </Button>
        )}
      </Stack>
      <RenderJsons
        jsonData={jsonData}
        validationResults={validationResults}
        validationResultsQuizAnswers={validationResultsQuizAnswers}
      />
    </div>
  );
};
