import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal } from "@mui/material";
import FormActions from "components/FormActions";
import React, { useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { IMaskInput } from "react-imask";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";

import Loading from "app/pages/home/components/Loading";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import store from "app/store/store";
import { UserType, UseSelectorI } from "interfaces";
import { useTranslation } from "_metronic/i18n/language";

const validationSchema = yup.object({
  nome: yup.string().required("Campo obrigatório"),
  email: yup
    .string()
    .email("Email inválido")
    .required("Campo obrigatório")
});

interface CadastroUsuarioModalProps {
  open: boolean;
  onClose: () => void;
  onCadastro?: any;
  userSelected?: UserType;
  userId?: number;
  onFinish: () => void;
}

const UserFormModal: React.FC<CadastroUsuarioModalProps> = ({
  open,
  onClose,
  onCadastro,
  userSelected,
  userId,
  onFinish
}) => {
  const translate = useTranslation();
  const { auth } = store.getState();
  const api = new AnanseApiService();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    control
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      nome: "",
      email: "",
      mobilePhone: ""
    }
  });

  const onSubmit = async (data: any) => {
    const payload = {
      ...data,
      profile: "USER",
      grouperId: userSelected?.id,
      usersLicense: 0,
      devicesLicense: 0,
      mobilePhone: `${data.mobilePhone.replace(/\s/g, '').replace(/[-()]/g, '')}` ?? null
    };

    setLoading(true);
    try {
      // const { id }: any = params;
      let reqBase: any;
      // console.log("payload put: ", userId);
      if (userId) {
        // console.log("payload put: ", payload);
        reqBase = { method: "PUT", url: `/usuario/${userId}` };
      } else {
        // console.log("payload post: ", payload);
        reqBase = { method: "POST", url: "/usuario" };

        // return;
      }

      const response = await api.makeHttpRequest({
        ...reqBase,
        data: payload
      });
      onCadastro(response);
      // console.log("response:", response);
      Swal.fire({
        title: translate("defaultMessages?.success"),
        text: translate("crudMessages.successText"),
        icon: "success"
      }).then((result: any) => {
        if (result.isConfirmed) {
          // history.push("/admin/account");
          onClose();
        }
      });
    } catch (e) {
      console.error(e);
      Swal.fire(
        translate("defaultMessages?.error"),
        translate("screens.user.errors.register"),
        "error"
      );
    } finally {
      setLoading(false);
      onClose();
      onFinish();
    }
  };

  const handleReset = () => {
    reset({});
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4
          // minWidth: 300
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body>
            <Row>
              <Form.Group as={Col} lg="12" xs="12">
                <Form.Label>
                  {translate("screenApp.listPacient.account.name")} *
                </Form.Label>

                <Form.Control
                  type="text"
                  placeholder={translate("screens.user.placeholders.name")}
                  {...register("nome")}
                />

                {errors.nome && (
                  <span className="invalid-input">{errors.nome.message}</span>
                )}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} lg="12" xs="12">
                <Form.Label>
                  {translate("screenApp.listPacient.account.email")} *
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder={
                    translate("screens.user.placeholders.email")
                  }
                  {...register("email")}
                />
                {errors.email && (
                  <span className="invalid-input">{errors.email.message}</span>
                )}
              </Form.Group>

              <Form.Group as={Col} lg="12" xs="12">
                <Form.Label>
                  {translate("screenApp.listPacient.account.phone")}
                </Form.Label>

                <Controller
                  name="mobilePhone"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Form.Control
                      type="text"
                      as={IMaskInput}
                      mask="+00 (00) 0 0000-0000"
                      placeholder={
                        translate("screens.user.placeholders.document")
                      }
                      value={value}
                      onChange={(value: any) => {
                        onChange(value.target.value);
                      }}
                    />
                  )}
                />
                {/* {errors.mobilePhone && (
                  <span className="invalid-input">{errors.phone.message}</span>
                )} */}
              </Form.Group>
            </Row>
          </Card.Body>
          <FormActions
            module="usuario"
            onReset={handleReset}
            onCancel={onClose}
          />
        </Form>
        <Loading isLoading={loading} />
      </Box>
    </Modal>
  );
};

export default UserFormModal;
