import React from "react";
import { connect } from "react-redux";
import { TableRow, TableCell, IconButton } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import { Edit, Info } from "@mui/icons-material";

import Datatable from "../../../../partials/datatable/Datatable";
import { CapsulesApiService } from "../../../../services/Ananse/CapsulesApiService";
import CrudActions from "app/partials/datatable/CrudActions";
import { TranslationContext } from "_metronic/i18n/language";

class CapsulesListing extends React.Component {
  static contextType = TranslationContext;
  constructor(props) {
    super(props);

    this.api = new CapsulesApiService();
    this.setCapsules = this.setCapsules.bind(this);
    this.initializeTranslation();
  }

  state = {
    capsules: [],
    reload: false,
    translate: null
  };

  async initializeTranslation() {
    const translate = await this.context;
    this.setState({ translate });
  }

  setCapsules(capsules) {
    this.setState({ capsules });
  }

  render() {
    const translate = this.context;
    window.setPageTitle(
      `${translate("screens.capsule.title")} - ${translate("labels_admin")}`
    );

    const headRows = [
      { label: translate("screens.dashboard.action")},
      {
        column: "SerialNumber",
        label: translate("screens.capsule.labels.serial")
      },
      {
        column: "Fragrance.Name",
        label: translate("screens.fragrance.list.title")
      },
      {
        column: "RemainingShots",
        label: translate("screens.capsule.labels.remaining")
      },
      {
        column: "Device.MacAddress",
        label: translate("screens.device.labels.macAddress")
      },
      {
        column: "DueDate",
        label: translate("screens.capsule.labels.dueDate")
      },
      {
        column: "CreatedAt",
        label: translate("screens.quiz.list.created")
      },
      {
        column: "UpdatedAt",
        label: translate("screens.quiz.list.updated")
      }
    ];

    const formatRow = r => {
      const crudActions = [];
      if (r.deletedAt === null) {
        crudActions.push("edit");
      }
      return (
        <TableRow hover tabIndex={-1} key={r.deviceId}>
          <TableCell scope="row">
            <Link
              to={{
                pathname: `/admin/capsules/update/${r.serialNumber}-${r.fragranceId}`,
                state: { data: r }
              }}
            >
              <IconButton
                // color="primary"
                aria-label="upload picture"
                component="label"
                size="small"
              >
                <Edit fontSize="inherit" />
              </IconButton>
            </Link>
            <Link
              to={{
                pathname: `/admin/capsules/detailing/${r.serialNumber}-${r.fragranceId}-${r.fragrance?.name}`,
                state: { data: r }
              }}
            >
              <IconButton
                aria-label="upload picture"
                component="label"
                size="small"
                title={translate("screenApp_results_detailing")}
              >
                <Info fontSize="inherit" />
              </IconButton>
            </Link>
          </TableCell>
          <TableCell scope="row">{r.serialNumber}</TableCell>
          <TableCell scope="row">{r.fragrance?.name}</TableCell>
          <TableCell scope="row">{r.remainingShots}</TableCell>
          <TableCell scope="row">{r.device?.macAddress}</TableCell>
          <TableCell scope="row">
            {moment(r.dueDate).format("DD/MM/YYYY HH:mm")}
          </TableCell>
          <TableCell scope="row">
            {moment(r.createdAt).format("DD/MM/YYYY HH:mm")}
          </TableCell>
          <TableCell scope="row">
            {moment(r.updatedAt).format("DD/MM/YYYY HH:mm")}
          </TableCell>
        </TableRow>
      );
    };

    return (
      <Datatable
        title={translate("screens.capsule.title")}
        endpoint="/capsules"
        headRows={headRows}
        formatRow={formatRow}
        rows={this.state.capsules}
        setRows={this.setCapsules}
        reload={this.state.reload}
        placeholderSearch={translate("screens.capsule.placeholders.filter")}
        buttons={[
          {
            label: translate("datatable.add"),
            onClick: null,
            icone: "add",
            path: "/admin/capsules/create"
          }
        ]}
      />
    );
  }
}

const mapStoreToProps = store => ({
  i18n: store.i18n
});

export default connect(mapStoreToProps, null)(CapsulesListing);
