import React, { useState, ChangeEvent } from "react";
import {
  FormControlLabel,
  Checkbox,
  TextField,
  Divider,
  Chip
} from "@mui/material";
import TransferList from "./TransferList";
import Grid from "@mui/material/Unstable_Grid2";

import { useSelector } from "react-redux";
import { UseSelectorI } from "interfaces";
import store from "app/store/store";

import { SelectPage } from "./Selects";
import { useTranslation } from "_metronic/i18n/language";

interface User {
  id: number;
  name: string;
}

interface UserRegistrationFormProps {
  isGrouper: boolean;
  setIsGrouper: React.Dispatch<React.SetStateAction<boolean>>;
  grouperId: number;
  setGrouperId: React.Dispatch<React.SetStateAction<any>>;
  usersLicense: number;
  setUsersLicense: React.Dispatch<React.SetStateAction<number>>;
  devicesLicense: number;
  setDevicesLicense: React.Dispatch<React.SetStateAction<number>>;
}

const GroupForm: React.FC<UserRegistrationFormProps> = ({
  isGrouper,
  setIsGrouper,
  devicesLicense,
  grouperId,
  setDevicesLicense,
  setGrouperId,
  setUsersLicense,
  usersLicense
}) => {
  const translate = useTranslation();
  const locale = useSelector(({ i18n }: UseSelectorI) => i18n.lang);
  // const [isGrouper, setIsGrouper] = useState<boolean>(false);
  // const [grouperId, setGrouperId] = useState<any>({});
  // const [usersLicense, setUsersLicense] = useState<number>(0);
  // const [devicesLicense, setDevicesLicense] = useState<number>(0);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [valueData, setValueData] = React.useState<string[]>([]);
  const [deviceData, setDeviceData] = React.useState<string[]>([]);

  const handleGrouperChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsGrouper(event.target.checked);
  };

  const handleSelectGrouperId = (event: ChangeEvent<HTMLInputElement>) => {
    console.log("handleSelectGrouperId 2: ", event.target.value);
    // setGrouperId(event.target.value);
  };

  const handleUsersLicenseChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setUsersLicense(value);
  };
  const handleDevicesLicenseChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setDevicesLicense(value);
  };

  const handleUserSelect = (user: User) => {
    setSelectedUsers(prevSelectedUsers => [...prevSelectedUsers, user]);
  };

  const checkDisableUsers = React.useCallback(() => {
    if (usersLicense >= valueData.length + 1) {
      return false;
    }
    return true;
  }, [usersLicense, valueData]);

  const checkDisableDevices = React.useCallback(() => {
    console.log("devicesLicense: ", devicesLicense);
    console.log("deviceData: ", deviceData.length);
    if (devicesLicense >= deviceData.length + 1) {
      return false;
    }
    return true;
    // return devicesLicense >= deviceData.length + 1;
  }, [devicesLicense, deviceData]);

  return (
    <form>
      <Divider textAlign="left" variant="middle" sx={{ marginY: 4 }}>
        <Chip label={translate('screens.group.title')} />
      </Divider>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isGrouper}
                onChange={handleGrouperChange}
                color="default"
              />
            }
            label={translate('screens.group.labels.grouper')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {isGrouper ? (
          <>
            <Grid xs={6}>
              <TextField
                type="number"
                label={translate('screens.group.labels.quantUsersLicense')}
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={usersLicense}
                onChange={handleUsersLicenseChange}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                type="number"
                label={translate('screens.group.labels.quantDevicesLicense')}
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={devicesLicense}
                onChange={handleDevicesLicenseChange}
              />
            </Grid>
          </>
        ) : (
          <div>
            <Grid xs md={12}>
              <SelectPage
                endPoint="/usuario"
                label={translate('screens.group.labels.select')}
                // showTotal
                value={grouperId}
                handleChange={(e: any) => {
                  const value = e;
                  setGrouperId(value.id);
                }}
                convertObject={(obj: any) => ({
                  id: obj.id,
                  value: obj.id,
                  label: `${obj.nome}`,
                  obj
                })}
              />
            </Grid>
          </div>
        )}
      </Grid>
    </form>
  );
};

export default GroupForm;
