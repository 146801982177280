import { useTranslation } from "_metronic/i18n/language";
import { CampaignType } from "app/crud";
import { CAMPAIGN_ID_FIELD, CampaignIdType } from "app/pages/admin/pages/Devices/DeviceUpdate";
import { SelectHTMLAttributes } from "react";
import { UseFormRegister } from "react-hook-form";

type SelectCampaignsProps = SelectHTMLAttributes<HTMLSelectElement> & {
  data: Array<CampaignType>;
  register: UseFormRegister<CampaignIdType>;
};

export const SelectCampaigns = ({
  data,
  register,
  ...rest
}: SelectCampaignsProps) => {
  const translate = useTranslation();

  return (
    <div className="col-lg-4 col-12">
      <label className="form-label">
        {translate("screens_quiz_list_campaign")}
      </label>
      <select {...rest} {...register(CAMPAIGN_ID_FIELD)}>
        {data.map(campaign => (
          <option key={campaign.id} value={campaign.id}>
            {campaign.name}
          </option>
        ))}
      </select>
    </div>
  );
};
