import React from "react";
import { Form, Col, Card, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import FormActions from "../../../components/FormActions";
import { AppInput } from "../../../../../partials/form";
import { withFormValidation } from "../../../../../hoc";
import { CategoryApiService } from "../../../../../services/Ananse/CategoryApiService";
import FormHelper from "../../../../../helpers/FormHelper";
import { TranslationContext } from "_metronic/i18n/language";

const initialState = {
  name: "",
  translate: null
};

class CategoryForm extends React.Component {
  static contextType = TranslationContext;
  constructor(props) {
    super(props);

    this.state = {
      ...initialState
    };

    this.api = new CategoryApiService();
    this.handleChange = this.props.handleChange.bind(this);
    this.onCleanForm = this.props.onCleanForm.bind(this);
    this.formHelper = new FormHelper(this);
    this.initializeTranslation();
  }

  async initializeTranslation() {
    const translate = await this.context;
    this.setState({ translate });
  }
  componentDidMount() {
    this.formHelper.loadFromService();
  }

  mapModelToState(model) {
    return {
      name: model.name
    };
  }

  mapStateToModel() {
    return {
      name: this.state.name
    };
  }

  render() {
    const translate = this.context;
    if (this.props.match.params.id) {
      window.setPageTitle(
        `${translate("screens.category.pageTitle.edit")} - ${translate("labels_admin")}`
      );
    } else {
      window.setPageTitle(
        `${translate("screens.category.pageTitle.create")}- ${translate("labels_admin")}`
      );
    }

    const { name } = this.state;

    return (
      <Card className="mt-3">
        <Card.Header>{translate("screens.category.mainData")}</Card.Header>
        <Card.Body>
          <Form onSubmit={this.formHelper.submit}>
            <Row>
              <Form.Group as={Col} lg="12" xs="12">
                <Form.Label>
                  {translate("screens.category.labels.name")} *
                </Form.Label>
                <AppInput
                  type="text"
                  name="name"
                  placeholder={translate("screens.category.placeholders.name")}
                  maxLength="100"
                  value={name}
                  onChange={this.handleChange}
                  validator={this.props.validator}
                  validations={"required|min:3|max:100"}
                />
              </Form.Group>
            </Row>

            <FormActions
              module="categories"
              formIsValid={true}
              onCleanForm={this.onCleanForm}
            />
          </Form>
        </Card.Body>
      </Card>
    );
  }
}

export default withRouter(withFormValidation(CategoryForm, initialState));
