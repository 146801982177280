export type OptionsType = {
  translationKey: string;
  value: string;
  filterType: "UserType";
};

export const userTypesData: OptionsType[] = [
  {
    translationKey: "screens_campaigns_list_title", // "Campanhas"
    value: "campaign",
    filterType: "UserType"
  },
  {
    translationKey: "labels_demonstration", // "Demonstração",
    value: "demonstration",
    filterType: "UserType"
  },
  {
    translationKey: "labels_official", // "Oficial",
    value: "official",
    filterType: "UserType"
  },
  {
    translationKey: "labels_search_title", // "Pesquisa",
    value: "search",
    filterType: "UserType"
  },
  {
    translationKey: "labels_test_development", // "Teste/desenvolvimento",
    value: "test_development",
    filterType: "UserType"
  }
];
