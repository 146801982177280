import React, { useState } from "react";

interface Fragrance {
  fragranceId: number;
  name: string;
  description: string;
}

interface Answer {
  quizQuestionAnswerId: number;
  answerId: number;
  answer: {
    label: string;
  };
}

interface Question {
  quizQuestionId: number;
  question: {
    quest: string;
  };
  answers: Answer[];
}

interface QuizProps {
  quizId: number;
  name: string;
  questions: Question[];
}

const Quiz: React.FC<QuizProps> = ({ name, questions: q }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswerId, setSelectedAnswerId] = useState<number | null>(null);
  const questions = q.sort((a, b) => a.quizQuestionId - b.quizQuestionId);
  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedAnswerId(null);
    }
  };

  const handleSelectAnswer = (answerId: number) => {
    setSelectedAnswerId(answerId);
  };

  const currentQuestion = questions[currentQuestionIndex];

  const scaleFactor = 0.4;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "90%"
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          maxWidth: "360px",
          width: "100%",
          textAlign: "center"
        }}
      >
        <p
          style={{
            marginTop: "40px",
            marginBottom: "10px",
            fontSize: `${14 * scaleFactor}px`,
            color: "#000000"
          }}
        >
          Você pode acionar o cheiro: 1 tempo(s).
        </p>
        <h2
          style={{
            marginTop: "20px",
            marginBottom: "15px",
            fontSize: `${20 * scaleFactor}px`,
            fontWeight: "normal",
            textAlign: "left"
          }}
        >
          {currentQuestion.question.quest}
        </h2>
        <div>
          {currentQuestion.answers.map(answer => (
            <div
              key={answer.quizQuestionAnswerId}
              style={{ marginBottom: "10px" }}
            >
              <button
                onClick={() => handleSelectAnswer(answer.answerId)}
                style={{
                  width: "100%",
                  padding: "7px",
                  borderRadius: "6px",
                  border:
                    selectedAnswerId === answer.answerId
                      ? `${4 * scaleFactor}px solid #9da5fa`
                      : `${4 * scaleFactor}px solid #444349`,
                  backgroundColor:
                    selectedAnswerId === answer.answerId ? "#F0F0FF" : "white",
                  fontSize: `${18 * scaleFactor}px`,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                {answer.answer.label}
                {selectedAnswerId === answer.answerId && (
                  <span
                    style={{
                      width: `${30 * scaleFactor}px`,
                      height: `${30 * scaleFactor}px`,
                      border: `${6 * scaleFactor}px solid #9da5fa`,
                      borderRadius: "50%",
                      backgroundColor: "#F0F0FF"
                    }}
                  ></span>
                )}
              </button>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px"
          }}
        >
          <button
            style={{
              flex: 1,
              padding: "10px 20px",
              borderRadius: "8px",
              border: "none",
              backgroundColor: "#FF6B6B",
              color: "white",
              fontSize: `${16 * scaleFactor}px`,
              cursor: "pointer",
              marginRight: "10px"
            }}
          >
            CHEIRO
          </button>
          <button
            onClick={handleNextQuestion}
            disabled={currentQuestionIndex >= questions.length - 1}
            style={{
              flex: 1,
              padding: "10px 20px",
              borderRadius: "8px",
              border: "none",
              backgroundColor: selectedAnswerId ? "#9da5fa" : "#C4C4C4",
              color: "white",
              fontSize: `${16 * scaleFactor}px`,
              cursor: "pointer"
            }}
          >
            SEGUINTE &gt;&gt;
          </button>
        </div>
        <div
          style={{
            marginTop: "20px",
            marginBottom: `${14 * scaleFactor}px`,
            fontSize: `${14 * scaleFactor}px`,
            color: "#ffffff",
            backgroundColor: "#a1a1a9",
            borderRadius: "5px",
            padding: "1px"
          }}
        >
          Pergunta {currentQuestionIndex + 1} / {questions.length}
        </div>
      </div>
    </div>
  );
};

// Dados de exemplo
const quizData = {
  quizId: 3,
  name: "Teste de Identificação 3",
  questions: [
    {
      quizQuestionId: 5,
      question: { quest: "1 - Este cheiro se parece com:" },
      answers: [
        {
          quizQuestionAnswerId: 174,
          answerId: 5,
          answer: { label: "Defumado" }
        },
        { quizQuestionAnswerId: 175, answerId: 2, answer: { label: "Cebola" } },
        {
          quizQuestionAnswerId: 176,
          answerId: 13,
          answer: { label: "Melao" }
        },
        { quizQuestionAnswerId: 177, answerId: 12, answer: { label: "Relva" } }
      ]
    }
  ]
};

interface Props {
  quiz: any;
}
const PreViewQuiz: React.FC<Props> = ({ quiz }) => {
  return <Quiz {...quiz} />;
};

export default PreViewQuiz;
