import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toAbsoluteUrl } from "../../../../_metronic";
import { useTranslation } from "_metronic/i18n/language";
import LanguageIcon from "@mui/icons-material/Language";
import { getLocales } from "app/services/Localise/localiseApi";
import { Box, Typography } from "@mui/material";

export default function SelectLanguage(props) {
  const translate = useTranslation();
  const [locales, setLocales] = useState([]);
  useEffect(() => {
    async function fetchLocales() {
      try {
        const localesData = await getLocales();
        setLocales(localesData);
      } catch (error) {
        console.error("Erro ao buscar locais:", error);
      }
    }
    fetchLocales();
  }, []);

  const options = locales.map(locale => ({
    value: locale.code,
    label: (
      <Box display="flex" alignItems="center">
        {props.languageValue === locale.code && <LanguageIcon />}
        <Typography ml={props.languageValue === locale.code ? 1 : 3}>{locale.name}</Typography>
      </Box>
    )
  }));

  return (
    <Select
      options={options}
      onChange={props.handleChangeLanguage}
      placeholder={translate("defaultMessages.selectLanguage")}
      value={options.find(option => option.value === props.languageValue)}
    />
  );
}
