import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { formatToCPF, formatToPhone, isCPF, isPhone } from "brazilian-values";
import { Card, Col, Form, Row } from "react-bootstrap";
import {
  Button,
  Icon,
  Stack,
  Divider,
  Select,
  MenuItem,
  SelectChangeEvent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import { useSelector } from "react-redux";
import { UseSelectorI } from "interfaces";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import { useEffect, useState } from "react";
import Loading from "app/pages/home/components/Loading";
import Swal from "sweetalert2";
import { Link, useParams, useHistory } from "react-router-dom";
import { IMaskInput } from "react-imask";
import IMask from "imask";
import store from "app/store/store";
import FormActions from "components/FormActions";
import { ToggleOff, ToggleOn } from "@mui/icons-material";
import { useTranslation } from "_metronic/i18n/language";
import { useRTL } from "themes/RTLProvider/RTLProvider";

const AccountEdit = ({ item }: any) => {
  const params = useParams();
  const history = useHistory();

  const translate = useTranslation();
  const isRtl = useRTL();
  const valueTransformIfRtl = isRtl ? "scaleX(-1)" : undefined;
  const { auth } = store.getState();
  const api = new AnanseApiService();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {}, []);

  const onSubmit = async () => {
    const { accountId }: any = item;
    let payload = item;

    setLoading(true);
    try {
      if (!item.deletedAt) {
        await api.makeHttpRequest({
          method: "DELETE",
          url: `/account/${accountId}`
        });
      } else {
        payload = { ...payload, ativo: true };
        console.log(payload);

        await api.makeHttpRequest({
          method: "DELETE",
          url: `/account/${accountId}`
        });
      }
      Swal.fire({
        title: translate("defaultMessages.success"),
        text: translate("crudMessages.successText"),
        icon: "success"
      }).then(result => {
        if (result.isConfirmed) {
          history.push("/admin/account");
        }
      });
    } catch (e) {
      console.error(e);
      Swal.fire({
        title: translate("defaultMessages.success"),
        text: translate("crudMessages.successText"),
        icon: "success"
      }).then(result => {
        if (result.isConfirmed) {
          history.go(0);

          history.push("/admin/account");
        }
      });
    } finally {
      setLoading(false);
      setVisible(false);

      history.push("/admin/account");
    }
  };

  return (
    <div>
      <IconButton
        size="small"
        // className={classes.button}
        title={item.deletedAt
          ? translate("changeStatus.active")
          : translate("changeStatus.inactive")
        }
        onClick={() => setVisible(!visible)}
      >
        {item.deletedAt ? (
          <ToggleOff fontSize="small" />
        ) : (
          <ToggleOn fontSize="small" />
        )}
      </IconButton>
      <Loading isLoading={loading} />

      <Dialog
        open={visible}
        onClose={setVisible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{transform: valueTransformIfRtl}}
      >
        <DialogTitle id="alert-dialog-title">
          {translate("changeStatus.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translate("changeStatus.message")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setVisible(false)}
            variant="contained"
            color="error"
            type="button"
            className="mr-3"
            style={{marginRight: isRtl ? 5 : 0}}
          >
            {translate("buttons.cancel")}
          </Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="success"
            className="btb-success"
            type="submit"
          >
            {translate("buttons.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AccountEdit;
