import { useTranslation } from "_metronic/i18n/language";
import React, { ChangeEvent } from "react";
import { Col, Form } from "react-bootstrap";

interface InputSelectProps {
  name?: string | undefined;
  label: string;
  validator: any; // substitua "any" pelo tipo correto para o seu validador
  value: string;
  handleChange: (event: ChangeEvent<any>) => void;
  selectData: { key: number; name: string }[];
  lg: string;
  xs: string;
  className?: any;
}

const InputSelect: React.FC<InputSelectProps> = ({
  name,
  validator,
  value: type,
  handleChange,
  selectData,
  lg,
  xs,
  label,
  className
}) => {
  const translate = useTranslation();
  return (
    <Form.Group as={Col} lg={lg} xs={xs} className={className}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        name={name}
        value={type}
        // validations={"required"}
        // validator={validator}
        onChange={handleChange}
        as="select"
      >
        <option value="">
          {translate('screenApp.quiz.selectOption')}
          </option>
        {selectData
          .map(p => (
            <option key={p.key} value={p.key}>
              {p.name}
            </option>
          ))
          .sort()}
      </Form.Control>
    </Form.Group>
  );
};

export default InputSelect;
