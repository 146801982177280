import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import React, { useEffect } from "react";
import { TableRow, TableCell, IconButton, Icon } from "@mui/material";
import Datatable from "app/partials/datatable/Datatable";
import store from "app/store/store";
import { Edit, OpenInBrowser, ToggleOff } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { UseSelectorI } from "interfaces";
import { titlePage } from "utils/titlepage";
import AdminDropdown from "app/partials/content/CustomDropdowns/AdminDropdown";
import DeletPatient from "components/table/Patient/DeletPatient";
import { format } from "date-fns";
import { filter } from "lodash";
import { useTranslation } from "_metronic/i18n/language";

const AccountListing = () => {
  const api = new AnanseApiService();
  const translate = useTranslation();
  const { auth } = store.getState();
  const endPoint = `/account/searchbyuserid?FilterInt=${auth.user.id}`;
  const [isActive, setIsActive] = React.useState("true");
  const [loading, setLoading] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [accounts, setAccounts] = React.useState([]);
  const [users, setUsers] = React.useState<any[]>([]);

  const titleP = translate("screenApp.listPacient.account.titleList");
  React.useEffect(() => {
    titlePage(`${titleP} - ${translate("labels_admin")}`);
  }, []);
  const headRows = [
    { label: translate("screens.dashboard.action") },
    { column: "Name", label: translate("screens.user.list.name") },
    { column: "Cpf", label: translate("screens.user.list.document") },
    { column: "Email", label: translate("screens.user.list.email") },
    { column: "UserId", label: translate("screens.user.list.user") },
    {
      column: "registrationDate",
      label: translate("screens.user.list.created")
    }
  ];

  const handleConfirmChangeStatus = (obj: any) => {};
  const handleSendConfirmationMail = (id: any) => {};
  const handleEdit = (id: any) => {};
  const handleConfirmDelete = (obj: any) => {};

  const getUserAll = async () => {
    const reqBase = { method: "GET", url: "/usuario" };
    const { total } = await api.makeHttpRequest({
      ...reqBase
    });
    const { data } = await api.makeHttpRequest({
      method: "GET",
      url: `/usuario?PerPage=2${total}`
    });
    setUsers(data);
  };

  useEffect(() => {
    getUserAll();
  }, []);

  const searchUserById = (id: number) => {
    const filterUser = users.find(value => value.id === id);
    return filterUser?.nome ?? "";
  };
  const formatRow = (r: any) => {
    const { auth } = store.getState();
    const isDiffAdmin =
      auth && auth.user && auth.user.profile.nome.toUpperCase() !== "ADMIN";
    const crudActions = [];
    if (r.confirmedAt && !isDiffAdmin) {
      crudActions.push("toggle", "edit");
    } else if (!r.confirmedAt && !isDiffAdmin) {
      crudActions.push(...["resend", "delete", "edit"]);
    } else {
      crudActions.push(...["resend"]);
      if (r.id === auth.user.id) {
        crudActions.push("edit");
      }
    }
    return (
      <TableRow hover tabIndex={-1} key={r.id}>
        <TableCell style={{ flexDirection: "row", flex: 1, display: "flex" }}>
          <DeletPatient item={r} />
          <Link
            to={{
              pathname: `/admin/account/edit/${r.accountId}`,
              state: { data: r }
            }}
          >
            <IconButton
              // color="primary"
              aria-label="upload picture"
              component="label"
              size="small"
            >
              <Edit fontSize="inherit" />
            </IconButton>
          </Link>
          <Link to={`/admin/result/${r.accountId}`}>
            <IconButton
              // color="primary"
              aria-label="upload picture"
              component="label"
              size="small"
            >
              <OpenInBrowser fontSize="inherit" />
            </IconButton>
          </Link>
        </TableCell>
        <TableCell scope="row">{r.name}</TableCell>
        <TableCell scope="row">{r.cpf}</TableCell>
        <TableCell>{r.email}</TableCell>
        <TableCell>{searchUserById(r.userId)}</TableCell>
        <TableCell>{format(new Date(r.createdAt), "dd/MM/yyyy")}</TableCell>
      </TableRow>
    );
  };

  return (
    <div>
      <Datatable
        title={translate("screenApp.listPacient.account.titleList")}
        endpoint={endPoint}
        headRows={headRows}
        formatRow={formatRow}
        rows={accounts}
        setRows={setAccounts}
        reload={reload}
        placeholderSearch={
          translate("screenApp.listPacient.SearchNameDoc")
        }
        mobile={AdminDropdown}
        width={window.innerWidth}
        isActive={isActive}
        buttons={[
          {
            label: translate("datatable.add"),
            onClick: null,
            icone: "add",
            path: "/admin/account/create"
          }
        ]}
        orderBy="Name"
        order="asc"
      />
    </div>
  );
};

export default AccountListing;
