import React from "react";
import { Modal, Spinner, Col, Row } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useTranslation } from "_metronic/i18n/language";
import { CircularProgressWithLabel } from "../Progress";

const useStyles = makeStyles(theme => ({
  modalBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  message: {
    fontSize: "1.1em"
  },
  spinnerBorder: {
    width: "3rem",
    height: "3rem"
  }
}));

export default function Loading(props) {
  const translate = useTranslation();
  function handleClose() {
    return false;
  }

  const classes = useStyles();
  const locale = useSelector(({ i18n }) => i18n.lang);

  return (
    <>
      {props.isLoading ? (
        <Modal
          show={true}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ zIndex: 2000 }}
          className="loading-modal"
          onHide={() => handleClose()}
        >
          <Modal.Body className={classes.modalBody}>
            <Row className="show-grid">
              <Col xs={12} md={12}>
                <h2>
                  {props.msg ? props.msg : translate("screens.loading.title")}
                </h2>
              </Col>
            </Row>
            <Row className="show-grid">
              <Col xs={12} md={12}>
                <p className={classes.message}>
                  {translate("screens.loading.message")}
                </p>
              </Col>
            </Row>
            <Row className="show-grid">
              {!!props.progress ? (
                <CircularProgressWithLabel value={props.progress} />
              ) : (
                <Spinner
                  animation="border"
                  variant="dark"
                  className={classes.spinnerBorder}
                  size="xs"
                />
              )}
            </Row>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
}
