import { AnanseApiService } from "app/services/Ananse/AnanseApiService";

const api = new AnanseApiService();

type DeviceType = {
  deviceId: number;
  macAddress: string;
  connectedUser: string;
  campaignId: number;
  ownerId: number;
  isDisplay: boolean;
};

export async function getDevice(macAddress: string): Promise<DeviceType> {
  const data = await api.makeHttpRequest({
    method: "GET",
    url: `/devices/mac/${macAddress}`
  });
  return {
    deviceId: data.deviceId,
    macAddress: data.macAddress,
    connectedUser: data.user.nome,
    campaignId: data.campaignId,
    ownerId: data.ownerId,
    isDisplay: data.isDisplay
  };
}
