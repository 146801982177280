import React from "react";
import { connect } from "react-redux";
import { TableRow, TableCell, IconButton, Modal } from "@mui/material";
import {
  BluetoothDisabled,
  PermDeviceInformation,
  Edit
} from "@mui/icons-material";
import moment from "moment";
import Datatable from "../../../../partials/datatable/Datatable";
import { DevicesApiService } from "../../../../services/Ananse/DevicesApiService";
import ConfirmChangeStatusDialog from "../../../../partials/datatable/ConfirmChangeStatusDialog";
import { getTokenByDevice } from "@noar-health/commons/lib/hash";
import Swal from "sweetalert2";
import Loading from "../../../home/components/Loading";
import styled from "styled-components";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { TranslationContext } from "_metronic/i18n/language";
import { Link } from "react-router-dom";

class DevicesListing extends React.Component {
  static contextType = TranslationContext;

  constructor(props) {
    super(props);

    this.api = new DevicesApiService();
    this.setDevices = this.setDevices.bind(this);
    this.disconnectDevice = this.disconnectDevice.bind(this);
    this.showTokenModal = this.showTokenModal.bind(this);
    this.initializeTranslation();
  }

  state = {
    translate: null,
    devices: [],
    reload: false,
    toggleConfirmDialog: false,
    showResetModal: false,
    resetFirmewareCode: null,
    remainingSecondsCode: null,
    selectedDevice: null,
    showLoading: false
  };

  async initializeTranslation() {
    // Aguarde até que o contexto seja definido
    const translate = await this.context;
    this.setState({ translate });
  }

  setDevices(devices) {
    this.setState({ devices });
  }

  async disconnectDevice() {
    await this.initializeTranslation();
    const { selectedDevice } = this.state;
    const { i18n } = this.props;
    const locale = i18n.lang;
    try {
      this.setState({ toggleConfirmDialog: false, showLoading: true });
      await this.api.removeUser(selectedDevice);

      this.setState({ showLoading: false });
      Swal.fire({
        title: this.state.translate("defaultMessages.success"),
        text: this.state.translate("screens.device.messages.success"),
        icon: "success",
        cancelButtonText: this.state.translate("buttons.exit"),
        onAfterClose: () => this.setState({ reload: true })
      });
    } catch (e) {
      console.log(e);
      this.setState({ showLoading: false });
      Swal.fire(
        this.state.translate("defaultMessages.error"),
        this.state.translate("screens.device.errors.disconnect"),
        "error"
      );
    }
  }

  async showTokenModal(device) {
    const result = getTokenByDevice(device.macAddress);
    //console.log(`get token from device: ${device.macAddress}`, result);
    const sleep = m => new Promise(r => setTimeout(r, m));
    this.setState({ showResetModal: true, resetFirmewareCode: result.token });

    for (let i = result.remainingSeconds; i > 0; i--) {
      if (
        this.state.showResetModal === false &&
        i !== result.remainingSeconds
      ) {
        break;
      }
      this.setState({ remainingSecondsCode: i });
      await sleep(1000);
    }

    if (this.state.showResetModal === true) {
      this.setState({
        resetFirmewareCode: "GETTING NEW",
        remainingSecondsCode: null
      });
      this.showTokenModal(device);
    }
  }

  render() {
    const translate = this.context;
    window.setPageTitle(
      `${translate("screens.device.title")} - ${translate("labels_admin")}`
    );

    const ModalContainer = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;

      width: fit-content;
      height: 100%;
      margin: auto;
      outline: none;
    `;

    const DivResetFirmeware = styled.div`
      border-radius: 3px;
    `;

    const headRows = [
      { label: translate("screens.quiz.list.actions") },
      {
        column: "MacAddress",
        label: translate("screens.device.labels.macAddress")
      },
      {
        column: "Campaign.Name",
        label: translate("screens.quiz.list.campaign")
      },
      {
        column: "User.Nome",
        label: translate("screens.device.labels.user")
      },
      {
        column: "UpdatedAt",
        label: translate("screens.quiz.list.updated")
      },
      {
        column: "Owner.Nome",
        label: translate("screens.device.labels.owner")
      },
      {
        column: "CreatedAt",
        label: translate("screens.quiz.list.created")
      },
      {
        column: "AppVersion",
        label: translate("screens.device.labels.versionApp")
      },
      {
        column: "remainingTests",
        label: translate("screens.device.labels.remainingTests")
      },
      {
        column: "isDisplay",
        label: translate("screens_device_display_title")
      }
    ];

    const formatRow = r => (
      <TableRow hover tabIndex={-1} key={r.deviceId}>
        <TableCell>
          <div
            className={{
              display: "flex",
              flexDirection: "row"
            }}
            key={"actions-" + r.deviceId}
          >
            <IconButton
              size="small"
              style={{
                margin: 0,
                color: "#0D2551"
              }}
              title={translate("screens.device.labels.modal")}
              onClick={() => this.showTokenModal(r)}
            >
              <PermDeviceInformation fontSize="small" />
            </IconButton>

            {r.user && r.user.nome && (
              <IconButton
                size="small"
                style={{
                  margin: 0,
                  color: "#0D2551"
                }}
                title={translate("screens.device.labels.disconnect")}
                onClick={() =>
                  this.setState({
                    toggleConfirmDialog: true,
                    selectedDevice: r.deviceId
                  })
                }
              >
                <BluetoothDisabled fontSize="small" />
              </IconButton>
            )}
            <Link
              to={{
                pathname: `/admin/devices/${r.macAddress}/update`
              }}
            >
              <IconButton
                size="small"
                style={{
                  margin: 0,
                  color: "#0D2551"
                }}
                title={translate("buttons.edit")}
              >
                <Edit fontSize="inherit" />
              </IconButton>
            </Link>
          </div>
        </TableCell>
        <TableCell scope="row">{r.macAddress}</TableCell>
        <TableCell scope="row">{r.campaign?.name}</TableCell>
        <TableCell scope="row">{r.user?.nome}</TableCell>
        <TableCell scope="row">
          {moment(r.updatedAt).format("DD/MM/YYYY HH:mm")}
        </TableCell>
        <TableCell scope="row">{r.owner?.nome}</TableCell>
        <TableCell scope="row">
          {moment(r.createdAt).format("DD/MM/YYYY HH:mm")}
        </TableCell>
        <TableCell scope="row">{r.appVersion}</TableCell>
        <TableCell scope="row">{r.remainingTests}</TableCell>
        <TableCell scope="row">
          {r.isDisplay
            ? translate("screenApp_modal_yes")
            : translate("screenApp_modal_no")}
        </TableCell>
      </TableRow>
    );

    return (
      <>
        <Loading isLoading={this.state.showLoading} />
        <ConfirmChangeStatusDialog
          open={this.state.toggleConfirmDialog}
          closeDialog={() =>
            this.setState({ toggleConfirmDialog: false, selectedDevice: null })
          }
          confirm={this.disconnectDevice}
          message={translate("screens.device.messages.disconnect")}
          title={translate("changeStatus.confirmTitle")}
        />
        {this.state.showResetModal && (
          <Modal
            open={this.state.showResetModal}
            onClose={() =>
              this.setState({
                showResetModal: false,
                selectedDevice: null,
                resetFirmewareCode: null
              })
            }
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <ModalContainer>
              <>
                <div className="container-modal">
                  <span className="title p-1 m-3 text-justify">
                    {translate("screens.device.messages.modal")}
                  </span>
                  <CountdownCircleTimer
                    isPlaying={true}
                    duration={60}
                    initialRemainingTime={this.state.remainingSecondsCode}
                    colors={[
                      ["#004777", 0.4],
                      ["#F7B801", 0.4],
                      ["#A30000", 0.2]
                    ]}
                  >
                    {({ remainingTime }) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <div style={{ fontSize: "3rem" }}>{remainingTime}s</div>
                        <DivResetFirmeware className="title shadow bg-white p-2 ">
                          {this.state.resetFirmewareCode}
                        </DivResetFirmeware>
                      </div>
                    )}
                  </CountdownCircleTimer>
                  {/* {this.state.remainingSecondsCode && <RemainingSeconds>
                    {this.state.remainingSecondsCode}s
                  </RemainingSeconds>} */}
                  <div className="d-flex justify-content-between pt-5">
                    <button
                      onClick={() =>
                        this.setState({
                          showResetModal: false,
                          selectedDevice: null
                        })
                      }
                      type="button"
                      className="btn btn-secondary"
                    >
                      {translate("buttons.close")}
                    </button>
                  </div>
                </div>
              </>
            </ModalContainer>
          </Modal>
        )}

        <Datatable
          title={translate("screens.device.title")}
          endpoint="/devices"
          headRows={headRows}
          formatRow={formatRow}
          rows={this.state.devices}
          setRows={this.setDevices}
          reload={this.state.reload}
          disableStatus={true}
          placeholderSearch={translate("screens.device.placeholders.filter")}
        />
      </>
    );
  }
}

const mapStoreToProps = store => ({
  i18n: store.i18n
});

export default connect(mapStoreToProps, null)(DevicesListing);
