import React, { CSSProperties, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import { FileUpload, InsertDriveFile } from "@mui/icons-material";
import { JsonResultType } from "../../types";
import { useTranslation } from "_metronic/i18n/language";

type Props = {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setValid: React.Dispatch<React.SetStateAction<boolean | null>>;
  setJsonData: React.Dispatch<React.SetStateAction<JsonResultType[]>>;
};

const InputMultFiles: React.FC<Props> = ({
  files,
  setFiles,
  setValid,
  setJsonData
}) => {
  const translate = useTranslation();
  const onClean = () => {
    setFiles([]);
    setValid(null);
    setJsonData([]);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValid(null);
    if (event.target.files && event.target.files.length > 0) {
      const selectedFiles = Array.from(event.target.files);
      setFiles(selectedFiles);

      selectedFiles.forEach(file => {
        const reader = new FileReader();
        reader.onload = e => {
          if (e.target && typeof e.target.result === "string") {
            try {
              const json = JSON.parse(e.target.result);
              setJsonData(prevData => [...prevData, json]);
            } catch (error) {
              console.error("Error parsing JSON:", error);
            }
          }
        };
        reader.readAsText(file);
      });
    }
  };

  return (
    <Card>
      <CardHeader title={translate("labels_jsonImport_title")} />
      <CardContent>
        {files.length ? (
          <Stack flexDirection="row" alignItems={"center"} flex={1}>
            <Typography>
              {files.length} {translate("labels_jsons")}
            </Typography>
            <Button
              variant="contained"
              color="warning"
              onClick={onClean}
              sx={{ flex: 1, marginLeft: "10px" }}
            >
              {translate("buttons_clean")}
            </Button>
          </Stack>
        ) : (
          <>
            <input
              accept="application/json"
              style={{ display: "none" }}
              id="raised-button-file"
              type="file"
              multiple
              onChange={handleFileChange}
            />
            <label htmlFor="raised-button-file">
              <Button
                variant="contained"
                color="success"
                component="span"
                endIcon={<FileUpload />}
                sx={{ flex: 1 }}
              >
                {translate("labels_selectJsonFiles")}
              </Button>
            </label>
          </>
        )}

        <FileList files={files} />
      </CardContent>
    </Card>
  );
};

const FileList = ({ files }: { files: File[] }) => {
  const [showAll, setShowAll] = useState(false);
  const translate = useTranslation();

  const displayedFiles = showAll ? files : files.slice(0, 10);

  return (
    <Stack flexDirection={showAll ? "column" : "row"}>
      <div style={styles.container as CSSProperties}>
        {displayedFiles.map(file => (
          <Tooltip key={file.name} title={file.name}>
            <IconButton>
              <InsertDriveFile />
            </IconButton>
          </Tooltip>
        ))}
      </div>
      {files.length > 10 && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? translate("labels_showLess") : translate("labels_showAll")}
        </Button>
      )}
    </Stack>
  );
};

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px"
  }
};

export default InputMultFiles;
