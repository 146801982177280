import React from "react";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme
} from "@mui/material/styles";
import {LightTheme} from './light'


export default function ThemeProvider(props) {
  const { children } = props;

  return <MuiThemeProvider theme={LightTheme}>{children}</MuiThemeProvider>;
}
