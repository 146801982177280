import React, { useRef, useState } from "react";
import JoditEditor from "jodit-react";
import { useTranslation } from "_metronic/i18n/language";
import { useRTL } from "themes/RTLProvider/RTLProvider";

interface Props {
  content: any;
  setContent: any;
}

const RichText = ({ content, setContent }: Props) => {
  const translate = useTranslation();
  const isRtl = useRTL();
  const editor = useRef(null);
  const [limitChar, setLimiteChar] = useState(false);

  const config: any = {
    readonly: false,
    defaultActionOnPasteFromWord: "insert_clear_html",
    askBeforePasteFromWord: false,
    placeholder: translate("screenApp.results.enterRemarksHere"),
    direction: isRtl ? "rtl" : "lrt"
  };

  return (
    <div>
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        onBlur={(newContent: any) => {
          setContent(newContent);
          // handleContentChange(newContent);
        }}
        onChange={newValue => {}}
      />
    </div>
  );
};

export default RichText;
