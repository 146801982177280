import React, { useEffect, useState } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { SortableItem } from "./SortableItem";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";
import { Sort } from "@mui/icons-material";
import { QuestionType } from "app/types";
import { QuestionApiService } from "app/services/Ananse/QuestionApiService";

interface Props {
  questions: QuestionType[];
  onSave: (updatedQuestions: QuestionType[]) => void;
}

const ArrangeSequenceModal: React.FC<Props> = ({ questions, onSave }) => {
  const [localQuestions, setLocalQuestions] = useState<QuestionType[]>(
    questions
  );
  const [showModal, setShowModal] = useState(false);

  const questionService = new QuestionApiService();

  useEffect(() => {
    setLocalQuestions(questions);
  }, [questions]);

  const handleClose = () => setShowModal(false);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = localQuestions.findIndex(
        item => item.quizQuestionId === active.id
      );
      const newIndex = localQuestions.findIndex(
        item => item.quizQuestionId === over.id
      );

      const updatedQuestions = arrayMove(localQuestions, oldIndex, newIndex);

      // Update the sequence
      updatedQuestions.forEach((question, index) => {
        question.arrangeQuestionSequence = index + 1;
      });

      setLocalQuestions(updatedQuestions);
    }
  };

  const handleSave = async () => {
    try {
      for (const question of localQuestions) {
        const req = {
          method: "PUT",
          url: `update-sequence/${question.quizQuestionId}/${question.arrangeQuestionSequence}`
        };
        console.log(req);
        await questionService.makeHttpRequest(req);
      }
    } catch (error) {
    } finally {
      onSave(localQuestions);
      handleClose();
    }
  };

  console.log("itens: ", localQuestions);
  return (
    <>
      <IconButton
        title="Organizar sequência de perguntas"
        onClick={() => setShowModal(true)}
      >
        <Sort />
      </IconButton>
      <Dialog open={showModal} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Organizar sequência de perguntas</DialogTitle>

        <DialogContent>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={localQuestions.map(item => item.quizQuestionId)}
              strategy={verticalListSortingStrategy}
            >
              {localQuestions.map((question, index) => (
                <SortableItem
                  key={question.quizQuestionId}
                  id={question.quizQuestionId}
                >
                  {`${index + 1}. ${question.quizQuestionId} - ${
                    question.arrangeQuestionSequence
                  }: ${question.quest || question.question.quest}`}
                </SortableItem>
              ))}
            </SortableContext>
          </DndContext>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="contained" onClick={handleClose}>
            Cancelar
          </Button>
          <Button color="success" variant="contained" onClick={handleSave}>
            Salvar ordem
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ArrangeSequenceModal;
