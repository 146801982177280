import { Clear, ExpandMore, Search } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import store from "app/store/store";
import { UserType, UseSelectorI } from "interfaces";
import * as React from "react";
import { useSelector } from "react-redux";
import ManageGroup from "./ManageGroup";
import { useTranslation } from "_metronic/i18n/language";

const api = new AnanseApiService();

export default function ShowAllGroups() {
  const { auth } = store.getState();
  const translate = useTranslation();
  const [expanded, setExpanded] = React.useState<string | false>(
    String(auth.user.id)
  );
  const [users, setUsers] = React.useState<UserType[]>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchTerm, setSearchTerm] = React.useState("");
  const itemsPerPage = 10;

  const loadData = async () => {
    try {
      const { total } = await api.makeHttpRequest({
        method: "GET",
        url: `/usuario`
      });
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/usuario?PerPage=${total}`
      });
      const usersData: UserType[] = response.data;
      const filterUserByGrouper = usersData.filter(
        value => value.grouper && value.usersLicense
      );
      setUsers(filterUserByGrouper);
    } catch (error) {}
  };

  React.useEffect(() => {
    loadData();
  }, []);

  const handleChange = (panel: string) => (
    event: React.SyntheticEvent,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const filteredUsers = users.filter(user =>
    user.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const pageCount = Math.ceil(filteredUsers.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentUsers = filteredUsers.slice(startIndex, endIndex);

  return (
    <div>
      <Stack>
        <TextField
          label={translate("labels.search")}
          placeholder={translate("labels.search")}
          variant="outlined"
          fullWidth
          value={searchTerm}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton onClick={handleClearSearch}>
                <Clear />
              </IconButton>
            )
          }}
          onChange={handleSearchChange}
          style={{ marginBottom: "16px", marginTop: "16px" }}
        />
      </Stack>
      {currentUsers.map(value => {
        return (
          <Accordion
            key={value.id}
            expanded={expanded === String(value.id)}
            onChange={handleChange(String(value.id))}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>{value.nome}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ManageGroup user={value} />
            </AccordionDetails>
          </Accordion>
        );
      })}

      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "16px"
        }}
      >
        <Pagination
          variant="outlined"
          shape="rounded"
          count={pageCount}
          page={currentPage}
          onChange={handlePageChange}
        />
      </Stack>
    </div>
  );
}
