import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { metronic, toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { getLocales } from "app/services/Localise/localiseApi";
import LanguageIcon from "@mui/icons-material/Language";

interface Language {
  code: string;
  name: string;
  lang: string;
}

interface Props {
  lang: string;
  iconType?: string;
  setLanguage: (lang: string) => void;
  setName?: (name: string) => void;
}

const LanguageSelector = ({ lang, iconType, setLanguage, setName }: Props) => {
  const [languages, setLanguages] = useState<Language[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const locales: any[] = await getLocales();
        const transformedLanguages = locales.map(language => ({
          ...language,
          lang: language.code
        })) as Language[];
        setLanguages(transformedLanguages);
      } catch (error) {
        console.error("Erro ao obter os idiomas: ", error);
      }
    };

    fetchLanguages();
  }, []);

  const currentLanguage = languages.find(x => x.lang === lang);

  const handleDropdownToggle = () => {
    setOpen(!open);
  };

  return (
    <Dropdown
      className="kt-header__topbar-item kt-header__topbar-item--langs"
      drop="down"
      show={open}
      onToggle={handleDropdownToggle}
    >
      <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-my-cart">
        <span
          className={clsx("kt-header__topbar-icon", {
            "kt-header__topbar-icon--brand": iconType === "brand"
          })}
        >
          <LanguageIcon />
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
        <ul className="kt-nav kt-margin-t-10 kt-margin-b-10">
          {languages.map(language => (
            <li
              key={language.lang}
              className={clsx("kt-nav__item", {
                "kt-nav__item--active": language.lang === currentLanguage?.lang
              })}
            >
              <span
                onClick={() => {
                  setLanguage(language.lang);
                  setOpen(false); // Set open to false directly
                  if (setName) {
                    setName(language.name);
                  }
                  //setTimeout(()=> window.location.reload(), 400);
                }}
                className={clsx("kt-nav__link", {
                  "kt-nav__link--active":
                    language.lang === currentLanguage?.lang
                })}
              >
                <span className="kt-nav__link-icon">
                  {language.lang === currentLanguage?.lang && <LanguageIcon />}
                </span>
                <span className="kt-nav__link-text ml-2">{language.name}</span>
              </span>
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = ({ i18n }: { i18n: { lang: string } }) => ({
  lang: i18n.lang
});

export default connect(
  mapStateToProps,
  metronic.i18n.actions
)(LanguageSelector);

export const LanguageSelect = LanguageSelector;
