import {
  Password,
  Person,
  Visibility,
  VisibilityOff
} from "@mui/icons-material";
import {
  Button,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField
} from "@mui/material";
import { useTranslation } from "_metronic/i18n/language";
import { VersionApp } from "components";
import { Formik } from "formik";
import queryString from "querystring";
import { useState } from "react";
import { WrappedComponentProps, injectIntl } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import styled from "styled-components";
import { login } from "../../crud/auth.crud";
import LanguageSelector from "../../partials/layout/LanguageSelector";
import * as auth from "../../store/ducks/auth.duck";
import Loading from "../home/components/Loading";
import ForgotPassword from "./ForgotPassword";
import RegisterOld from "./Register/indexOld";
import UserLogin from "./UserLogin";
import Toast from "./UserLogin/components/Toast";

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 100%;
  position: relative;
  margin: auto;
  outline: none;
`;

interface FormValues {
  email: string;
  password: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type RouterProps = RouteComponentProps;
type Props = PropsFromRedux & RouterProps & WrappedComponentProps;

const Login: React.FC<Props> = props => {
  const translate = useTranslation();
  const {} = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isUserLogged, setIsUserLogged] = useState(false);
  const [error] = useState<string | null>(null);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });
  const [showPassword, setShowPassword] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const enableLoading = () => {
    setIsLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setIsLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const isLoginExpired = () => {
    const query = queryString.parse(window.location.search.replace("?", ""));
    return query && query.hasOwnProperty("expired");
  };

  function checkIsUser(): Promise<boolean> {
    return new Promise(resolve => {
      setTimeout(() => {
        const auth = localStorage.getItem("persist:auth");
        const json = JSON.parse(auth!);
        const user = JSON.parse(json.user);
        resolve(user.profile.nome === "POSTNEWS");
      }, 2000);
    });
  }

  const handleCloseToast = () => {
    setToastOpen(false);
  };

  window.setPageTitle(translate("labels_login"));

  return (
    <>
      <Loading isLoading={isLoading} />
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-header__topbar__login d-flex flex-row-reverse">
              <LanguageSelector iconType="" />
          </div>
          <div className="kt-login__title">
            <h3>{translate("screens.login.title")}</h3>
          </div>

          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            validate={(values: FormValues) => {
              const errors: Partial<FormValues> = {};
              if (!values.email) {
                errors.email = translate("screens.login.validations.required");
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = translate("screens.login.validations.invalid");
              }

              if (!values.password) {
                errors.password = translate(
                  "screens.login.validations.required"
                );
              }
              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              login(values.email, values.password)
                .then(async authData => {
                  props.login(authData);

                  const isUser = await checkIsUser();
                  if (isUser) {
                    setIsLoading(false);
                    setSubmitting(false);
                    setIsUserLogged(true);
                    setStatus("");
                  } else {
                    disableLoading();
                    props.history.push("/admin");
                  }
                })
                .catch(e => {
                  disableLoading();
                  setSubmitting(false);
                  setToastMessage(translate("screens.login.validations.loginInvalid"));
                  setToastOpen(true);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {isLoginExpired() && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">
                      {translate("screens.login.validations.sessionExpired")}
                    </div>
                  </div>
                )}

                {error && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{error}</div>
                  </div>
                )}

                <TextField
                  type="email"
                  label={translate("screens.login.email")}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  helperText={touched.email && errors.email}
                  error={Boolean(touched.email && errors.email)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    )
                  }}
                />

                <TextField
                  type={showPassword ? "text" : "password"}
                  margin="normal"
                  label={translate("screens.login.password")}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Password />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <Stack
                  sx={{ marginTop: "10px" }}
                  justifyContent="center"
                  direction={{ xs: "column" }}
                  spacing={2}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    disabled={isSubmitting}
                  >
                    {translate("screens.login.login")}
                  </Button>
                </Stack>

                <Stack
                  sx={{ marginTop: "10px" }}
                  justifyContent="center"
                  direction={{ xs: "column", sm: "row" }}
                  spacing={1}
                >
                  <ForgotPassword />
                  <RegisterOld />
                  {/* <Link to="/register" style={{ flex: 1 }}>
                    <Button type="button" color="success" fullWidth>
                      {translate("screens.login.noAccount")}
                    </Button>
                  </Link> */}

                  <Modal
                    open={isUserLogged}
                    onClose={() => setIsUserLogged(false)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    <ModalContainer>
                      <UserLogin
                        bg="white"
                        closeModal={() => setIsUserLogged(false)}
                      />
                    </ModalContainer>
                  </Modal>
                </Stack>

                <Stack
                  sx={{ marginTop: "10px" }}
                  justifyContent="space-between"
                  direction="row"
                >
                  <VersionApp />
                  <Link
                    to="/privacy-policy"
                    className="kt-link kt-login__link-privacy-policy"
                  >
                    {translate("defaultMessages.privacyPolicy")}
                  </Link>
                </Stack>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <Toast
        open={toastOpen}
        message={toastMessage}
        type={'error'}
        onClose={handleCloseToast}
      />
    </>
  );
};

const connector = connect(null, auth.actions);
export default injectIntl(connector(Login));
